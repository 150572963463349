@media print{
  .hidden-print {
    display: none;
  }

  #site-header nav,
  #site-actions ul,
  #site-footer,
  #haiku-feedback-tab,
  .iframe-container {
    display: none;
  }

  header .col-sm-6 {
    float: left;
  }

  header .col-sm-6:last-child {
    float: right;
  }

  header .col-sm-6:last-child::after {
    clear: both;
    content: "";
    display: table;
  }

  .carousel-control {
    display: none;
  }

  a {
    background: transparent !important;
    background-image: none !important;
    border-radius: 0 !important;
    filter: none !important;
  }

  a::after {
    border: 0 !important;
    display: none !important;
  }}
