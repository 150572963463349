.modal-content .altmetric-embed,
.altmetric-left .altmetric-embed {
  padding: 0 1.16em 0 0;
}

.modal-content [data-badge-type*=donut].altmetric-embed,
.altmetric-left [data-badge-type*=donut].altmetric-embed {
  float: left;
}

.modal-content [data-badge-details="*"].altmetric-embed,
.altmetric-left [data-badge-details="*"].altmetric-embed {
  float: none;
}

.altmetric-embed {
  margin: 0.64em 0;
}

.altmetric-badge {
  margin: 0.64em 0;
}

div#_altmetric_popover_el.altmetric-embed {
  z-index: 1060 !important;
}
