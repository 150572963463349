.border-primary { border-color: var(--color-primary); }
.border-primary-100 { border-color: var(--color-primary-100); }
.border-primary-200 { border-color: var(--color-primary-200); }
.border-primary-300 { border-color: var(--color-primary-300); }
.border-primary-400 { border-color: var(--color-primary-400); }
.border-primary-500 { border-color: var(--color-primary-500); }
.border-primary-600 { border-color: var(--color-primary-600); }
.border-primary-700 { border-color: var(--color-primary-700); }
.border-primary-800 { border-color: var(--color-primary-800); }
.border-primary-900 { border-color: var(--color-primary-900); }

.border-success { border-color: var(--color-success); }
.border-success-100 { border-color: var(--color-success-100); }
.border-success-200 { border-color: var(--color-success-200); }
.border-success-300 { border-color: var(--color-success-300); }
.border-success-400 { border-color: var(--color-success-400); }
.border-success-500 { border-color: var(--color-success-500); }
.border-success-600 { border-color: var(--color-success-600); }
.border-success-700 { border-color: var(--color-success-700); }
.border-success-800 { border-color: var(--color-success-800); }
.border-success-900 { border-color: var(--color-success-900); }

.border-info { border-color: var(--color-info); }
.border-info-100 { border-color: var(--color-info-100); }
.border-info-200 { border-color: var(--color-info-200); }
.border-info-300 { border-color: var(--color-info-300); }
.border-info-400 { border-color: var(--color-info-400); }
.border-info-500 { border-color: var(--color-info-500); }
.border-info-600 { border-color: var(--color-info-600); }
.border-info-700 { border-color: var(--color-info-700); }
.border-info-800 { border-color: var(--color-info-800); }
.border-info-900 { border-color: var(--color-info-900); }

.border-warning { border-color: var(--color-warning); }
.border-warning-100 { border-color: var(--color-warning-100); }
.border-warning-200 { border-color: var(--color-warning-200); }
.border-warning-300 { border-color: var(--color-warning-300); }
.border-warning-400 { border-color: var(--color-warning-400); }
.border-warning-500 { border-color: var(--color-warning-500); }
.border-warning-600 { border-color: var(--color-warning-600); }
.border-warning-700 { border-color: var(--color-warning-700); }
.border-warning-800 { border-color: var(--color-warning-800); }
.border-warning-900 { border-color: var(--color-warning-900); }

.border-danger { border-color: var(--color-danger); }
.border-danger-100 { border-color: var(--color-danger-100); }
.border-danger-200 { border-color: var(--color-danger-200); }
.border-danger-300 { border-color: var(--color-danger-300); }
.border-danger-400 { border-color: var(--color-danger-400); }
.border-danger-500 { border-color: var(--color-danger-500); }
.border-danger-600 { border-color: var(--color-danger-600); }
.border-danger-700 { border-color: var(--color-danger-700); }
.border-danger-800 { border-color: var(--color-danger-800); }
.border-danger-900 { border-color: var(--color-danger-900); }
