table.table-simple-gallery tr td,
table.table-simple-gallery tr th {
  border: 0;
  line-height: 1;
  padding: 0;
  text-align: center;
}

.table-simple-gallery {
  border: 0;
}

/*
OMD-3534
table.table-simple-gallery tr td p,
table.table-simple-gallery tr th p {
  font-size: 0.64rem;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}
*/

table.table-simple-gallery tr td img,
table.table-simple-gallery tr th img {
  cursor: zoom-in;
  display: inline-block;
}

.rich-text .table-simple-gallery .image-inline {
  display: inherit !important;
}

.mfp-figure img.mfp-img {
  padding: 40px 0 1.48rem 0;
}

.mfp-figure figure figcaption,
.mfp-figure::after {
  background: transparent !important;
  box-shadow: none !important;
}

.mfp-figure figure figcaption .mfp-bottom-bar {
  background-color: var(--color-primary);
  padding: 0.5em;
}

.mfp-figure figure figcaption small {
  color: var(--color-white);
  font-weight: normal;
  position: static;
  text-shadow: none;
}
