.masonry-grid {
  margin: 1.16em 0;
}

.masonry-grid .grid-item {
  margin-top: 1.16em;
  padding-left: 0;
}

.masonry-grid .grid-item h3,
.masonry-grid .grid-item .heading-3 {
  border-bottom: 1px solid var(--color-gray-200);
}
