#contentchooser-content-show-button {
  background-color: var(--color-primary);
  color: var(--color-primary-100);
  font-family: var(--body-font-family);
  text-shadow: none;
}

#contentchooser-content-show-button:hover {
  background-color: var(--color-primary-600);
}

#contentchooser-content-search .item-list li {
  margin: 0;
}

#contentchooser-content-search .item-list li a {
  padding: 0.64em;
}

#contentchooser-content-search .item-list li:hover {
  background-color: var(--color-gray-100);
}

.template-compose .edit-tile-link {
  background-color: var(--color-info-100);
  border-color: var(--color-info-800);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.32);
  color: var(--color-info-700);
  height: auto;
  right: -0.8em;
  top: -0.8em;
  z-index: 3;
}
