.comment .commentDate {
  border-bottom: 1px solid var(--color-gray-400);
  color: var(--subtext-font-color);
  color: var(--text-details);
  font-size: 0.64rem;
  line-height: 0.88em;
  padding-bottom: 0.32em;
}

.comment {
  border: none;
  border-bottom: 3px solid var(--color-gray);
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 1.32em;
  padding-top: 1.32em;
}

.comment .documentByLine {
  background-color: transparent;
}

.comment .commentActions {
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-200);
  border-radius: 3px;
  padding: 0.64em 0.9em;
}

.comment.replyTreeLevel1 {
  margin-left: 1.5em;
}

.comment.replyTreeLevel2 {
  margin-left: 3em;
}

.comment.replyTreeLevel3 {
  margin-left: 6em;
}

.comment.replyTreeLevel4 {
  margin-left: 7.5em;
}

.comment.replyTreeLevel5 {
  margin-left: 9em;
}

#commenting {
  margin-top: 1.6em;
}
