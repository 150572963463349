.post-info p {
  line-height: 1.12em;
  margin: 0.32em 0;
}

.post-info p:last-child {
  margin-bottom: 0;
}

.post-info p.text-small > i {
  font-size: 0.64rem;
}

.post-info i {
  border: 0;
  color: var(--body-font-color) !important;
  height: 1.16em;
  line-height: 1.16em;
  width: 1.16em;
}

.post-info i:hover {
  background-color: transparent !important;
  color: var(--body-font-color) !important;
}

.post-info i.glyphicon-calendar {
  background-color: transparent !important;
  color: var(--body-font-color) !important;
}

.commentActions form {
  display: inline-block;
}

.commentActions .btn {
  margin: 0.32em 0.64em;
}
