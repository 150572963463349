/*
  Images

  Images. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/css/#images)

  Styleguide 6

  Responsive images

  Images can be made responsive by adding the `.img-responsive` class to the `img` object.

  Markup:
  <img src="http://placehold.it/150x150" class="img-responsive" alt="super image">

  Styleguide 6.1

  Image styles

  Images can have different styles (similar to bootstrap) by adding various classes to the `img` object.

  Markup:
  <img src="http://placehold.it/150x150" class="img-rounded" alt="super image">
  <img src="http://placehold.it/150x150" class="img-circle" alt="super image">
  <img src="http://placehold.it/150x150" class="img-thumbnail" alt="super image">

  Styleguide 6.2
*/

.logo-floated-right {
  float: right;
}

#primary-logo img {
  margin-top: 0.64em;
}

#primary-logo img,
.logo-secondary,
.logo-footer {
  max-height: 100px;
  max-width: 100%;
}

#site__secondary-logo {
  float: right;
}

#site__secondary-logo .logo-secondary {
  margin-left: 1.88em;
}

.logo-footer {
  margin-top: 0.64em;
}

.image-container {
  position: relative;
}

/*
  Media container

  Deprecated: For responsive embeds, we use a `.iframe-container` class to wrap the `iframe` object. This works well for embeds such as Youtube, Vimeo or Google Maps.

  Markup:
  <div class="iframe-container"><iframe src="https://www.youtube.com/embed/p5CDNsUBJjA?rel=0" frameborder="0" allowfullscreen></iframe></div>

  Styleguide 6.3
*/

.iframe-container {
  height: 0;
  margin-bottom: 16px;
  overflow: hidden;
  padding-bottom: 57.25%;
  padding-top: 25px;
  position: relative;
}

.iframe-container > iframe,
.iframe-container .iframe > iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.img-zoom:hover img, .img-zoom:focus img, .img-zoom:active img {
  opacity: 0.5;
}

.image-modal,
body.richtext-modals .rich-text img {
  cursor: zoom-in;
}

body.richtext-modals .rich-text a img {
  cursor: pointer;
}

.rich-text .image-inline img {
  display: block;
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.figure-primary {
  display: block;
  margin-bottom: 20px;
}

span.figure,
figure {
  max-width: 100%;
}

span.figure small,
figure small {
  background-color: transparent;
  color: var(--color-white);
}

span.figure small.inverted,
figure small.inverted {
  background-color: var(--color-primary);
  bottom: 0;
  padding: 0.48em 0.64em;
  right: 0;
}

span.figure img,
figure img {
  max-width: 100%;
}

/*
  Responsive embeds

  For responsive embeds, we use a `.embed-responsive` and `.embed-responsive-16by9` or `.embed-responsive-4by3` classes to wrap the `iframe` object. We also apply a class of `.embed-responsive-item` to the `iframe` itself.

  Markup:
  <div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/p5CDNsUBJjA?rel=0" frameborder="0" allowfullscreen></iframe></div>

  Styleguide 6.3.1
*/

@media (max-width: 991px){
  .logo-floated-right {
    float: none;
  }}

@media (min-width: 768px){
  .logo-footer {
    float: right;
  }}
