/*
  Buttons

  The buttons used throughout the site.

  Styleguide 5
*/

.link-button {
  padding: 0.64em 4.24em;
  text-decoration: none;
  text-shadow: none;
  transition: all 0.3s ease;
}

a.btn:hover, a.btn:focus,
.link-button:hover, .link-button:focus {
  cursor: pointer;
  text-decoration: none;
}

.infinite-scroll-button-holder {
  margin: 1.16em 0;
  text-align: center;
  width: 100%;
}

.load-more-button {
  background-color: var(--color-gray-100);
  background-image: none;
  border: 1px solid var(--color-gray-200);
  color: var(--color-gray-700);
  display: block;
  font-weight: 500;
  padding: 0.64em 1.32em;
  text-shadow: none;
  transition: all 0.3s ease;
  width: 100%;
}

.load-more-button:hover,
.load-more-button:focus {
  background-color: var(--color-gray-200);
  color: var(--color-gray-700);
}

.load-more-button .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

/*
  Theme buttons

  Use the button classes on an `<a>`, `<button>`, or `<input>` element in addition to the base `.btn` class.

  .btn-default         -  The default button style
  .btn-primary         -  The main button for a theme
  .btn-success         -  The button showcases a success action
  .btn-info            -  The button showcases an info action
  .btn-warning         -  The button showcases a warning action
  .btn-danger          -  The button showcases a danger action
  .btn-outline         -  The button is similar to bootstrap's btn-default

  Markup:
  <a href="#" class="btn {{modifier_class}}" title="My button title">Button</a>

  Styleguide 5.1
*/

.btn-primary {
  background: var(--theme-button-color);
  border-bottom-left-radius: var(--base-border-radius);
  border-bottom-right-radius: 0;
  border-color: var(--theme-button-color);
  border-top-left-radius: var(--base-border-radius);
  border-top-right-radius: 0;
  color: var(--color-white);
  font-size: 0.8rem;
  margin: 0.64em 0;
  padding-right: 1em;
  position: relative;
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-primary::after {
  border-bottom: 1.32em solid transparent;
  border-left: 1.32em solid;
  border-left-color: var(--theme-button-color);
  border-top: 1.32em solid transparent;
  content: "";
  margin-top: -1.2em;
  position: absolute;
  right: -1.32em;
  top: 48.5%;
  transition: all 0.3s ease;
  z-index: 2;
}

.btn-primary:hover {
  background: var(--link-color-hover);
  border-color: var(--link-color-hover);
  color: var(--color-white);
}

.btn-primary:hover::after {
  border-left-color: var(--link-color-hover);
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning,
.btn-default {
  background-image: none;
  filter: none;
  transition: all 0.3s ease;
}

.haiku-slideshow .btn-secondary {
  background: var(--color-white);
  border-bottom-left-radius: var(--base-border-radius);
  border-bottom-right-radius: 0;
  border-color: var(--color-white);
  border-top-left-radius: var(--base-border-radius);
  border-top-right-radius: 0;
  color: var(--theme-button-color);
  font-size: 0.8rem;
  margin: 0.64em 0;
  padding-right: 1em;
  position: relative;
  text-shadow: none;
  transition: all 0.3s ease;
}

.haiku-slideshow .btn-secondary::after {
  border-bottom: 1.32em solid transparent;
  border-left: 1.32em solid;
  border-left-color: var(--color-white);
  border-top: 1.32em solid transparent;
  content: "";
  margin-top: -1.2em;
  position: absolute;
  right: -1.32em;
  top: 48.5%;
  transition: all 0.3s ease;
  z-index: 2;
}

.haiku-slideshow .btn-secondary:hover {
  background: var(--theme-button-color-hover);
  border-color: var(--theme-button-color-hover);
  color: var(--color-white);
}

.haiku-slideshow .btn-secondary:hover::after {
  border-left-color: var(--theme-button-color-hover);
}

.btn-outline-secondary {
  background: transparent;
  border-radius: var(--base-border-radius);
  border-color: var(--theme-button-color);
  color: var(--theme-button-color);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  background: var(--theme-button-color-hover);
  border-color: var(--theme-button-color-hover);
  color: var(--color-white);
  text-decoration: none !important;
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-500);
  color: var(--color-gray-500);
}

.btn-default,
.link-button {
  background-color: var(--theme-button-color);
  border-color: var(--theme-button-color);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-default:hover,
.btn-default:focus,
.link-button:hover,
.link-button:focus {
  background-color: var(--link-color-hover);
  color: var(--color-white);
}

.btn-default .caret,
.link-button .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.btn-outline {
  background-color: transparent;
  border-color: var(--color-primary);
  border-radius: var(--base-border-radius);
  color: var(--color-primary);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-outline:hover,
.btn-outline:focus {
  background-color: transparent;
  border-color: var(--link-color-hover);
  color: var(--color-primary);
}

.btn-success {
  background-color: var(--color-success);
  border-color: var(--color-success);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-success:hover,
.btn-success:focus {
  background-color: var(--color-success-700);
  color: var(--color-white);
}

.btn-success .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.btn-info {
  background-color: var(--color-info);
  border-color: var(--color-info);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-info:hover,
.btn-info:focus {
  background-color: var(--color-info-700);
  color: var(--color-white);
}

.btn-info .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.btn-danger {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: var(--color-danger-700);
  color: var(--color-white);
}

.btn-danger .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.btn-warning {
  background-color: var(--color-warning);
  border-color: var(--color-warning);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.btn-warning:hover,
.btn-warning:focus {
  background-color: var(--color-warning-700);
  color: var(--color-white);
}

.btn-warning .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

/*
  Sizes

   Add `.btn-lg`, `.btn-sm`, or `.btn-xs` for additional sizes, in addition to the base class `.btn` and any color styles.

  .btn-xs              -  The smallest button
  .btn-sm              -  The small button
  .btn-lg              -  The largest button

  Markup:
  <a href="#" class="btn btn-default {{modifier_class}}" title="My button title">Button</a>

  Styleguide 5.2
*/
