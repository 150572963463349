.navbar-nav li ul.dropdown-menu.show {
  border: 1px solid var(--color-gray-800);
  border-radius: 0;
  height: auto;
  opacity: 1;
  transition: transform 0.12s cubic-bezier(0.2, 0, 0.2, 1) !important;
  visibility: visible;
}

.navbar-inverse {
  background-color: var(--color-primary);
  border-color: transparent;
}
