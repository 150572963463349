/*
  Panels

  Panels. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/components/#panels)

  .panel-default     - The default styles
  .panel-primary     - A primary theme panel
  .panel-success     - A success panel
  .panel-info        - An info panel
  .panel-warning     - A warning panel
  .panel-danger      - An error panel

  Markup:
  <div class="panel {{modifier_class}}">
    <div class="panel-heading">
      <h3 class="panel-title">Panel title</h3>
    </div>
    <div class="panel-body">
      Panel content
    </div>
    <div class="panel-footer">Panel footer</div>
  </div>

  Styleguide 8.6
*/

.site-sidebar .panel > .panel-heading,
.tile-content .panel > .panel-heading {
  background-color: transparent;
  background-image: none;
  filter: none;
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
  padding: 0.64em 0;
  text-rendering: var(--heading-text-rendering);
  text-transform: uppercase;
}

.site-sidebar .panel .panel-body .nav,
.tile-content .panel .panel-body .nav {
  margin: 0;
}

.site-sidebar .panel .panel-body .nav > li.active > a,
.tile-content .panel .panel-body .nav > li.active > a {
  background-color: transparent;
  color: var(--body-font-color);
}

.site-sidebar .panel .panel-body .nav > li > a,
.tile-content .panel .panel-body .nav > li > a {
  padding: 0.64em 0;
  padding: 0;
}

.site-sidebar .panel .panel-body .nav > li > a:hover,
.site-sidebar .panel .panel-body .nav > li > a:focus,
.tile-content .panel .panel-body .nav > li > a:hover,
.tile-content .panel .panel-body .nav > li > a:focus {
  background-color: transparent;
  text-decoration: var(--link-text-decoration);
}

.site-sidebar .panel .panel-body > .nav > li,
.tile-content .panel .panel-body > .nav > li {
  border-bottom: 1px solid var(--color-gray-200);
}

.site-sidebar .panel .panel-body > .nav > li:last-child,
.tile-content .panel .panel-body > .nav > li:last-child {
  border-bottom: 0;
}

.site-sidebar .panel,
.tile-content .panel {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.site-sidebar .panel .panel-body,
.tile-content .panel .panel-body {
  margin: 0;
  padding: 0;
}

.site-sidebar .panel .panel-body > .nav > li > a,
.tile-content .panel .panel-body > .nav > li > a {
  font-weight: bold;
}

.panel .panel-body a[class*=state-],
.panel .panel-body a.link-external {
  font-weight: 600;
}

.panel.panel-accordion .panel-heading .heading {
  color: var(--heading-color);
  font-family: var(--body-font-family);
  font-size: 1.12rem;
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  line-height: var(--base-line-height);
  padding: 0.64em 0;
}

.panel.panel-accordion .panel-heading .heading a:active,
.panel.panel-accordion .panel-heading .heading a:focus,
.panel.panel-accordion .panel-heading .heading a:hover {
  color: var(--subtext-font-color);
  text-decoration: none;
}

#site-content-sidebar .panel-default,
.tile-content .panel-default {
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

#site-content-sidebar .panel-default .panel-heading,
.tile-content .panel-default .panel-heading {
  background-color: transparent;
  padding: 10px 10px 10px 0;
  text-transform: uppercase;
  text-transform: var(--heading-text-transform);
}

#site-content-sidebar .panel-default .panel-body,
.tile-content .panel-default .panel-body {
  padding: 0 0.64em 0.64em 0;
}

#site-content-sidebar .panel-default ul.list-unstyled,
.tile-content .panel-default ul.list-unstyled {
  margin-left: 0;
}

/*
  Accordions

  Accordions. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/javascript/#collapse)

  Markup:
  <div class="list-accordion">
    <div class="panel">
       ...
    </div>
  </div>

  Styleguide 8.6.1
*/

/* The accordion for the archive on the Events page */

.list-accordion .panel {
  margin-top: 0;
}

.list-accordion .panel:first-child {
  margin-top: 0.64em;
}

.list-accordion .panel-heading {
  padding: 0 !important;
}

.list-accordion .panel-heading .panel-title {
  color: var(--text-details);
  font-family: var(--body-font-family);
  font-size: 0.88rem;
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  line-height: var(--base-line-height);
}

.list-accordion .panel-heading .panel-title:hover {
  color: var(--text-details);
}

.list-accordion .panel-collapse > .panel-body > .nav {
  margin-left: 0.64em !important;
}

.list-accordion .panel-collapse > .panel-body > .nav li {
  border-bottom: 0 !important;
  padding: 0.48em 0;
}

.list-accordion .panel-collapse > .panel-body > .nav li a {
  font-size: 0.8rem;
  font-weight: 300 !important;
}

.list-accordion .panel-collapse > .panel-body > .nav li a,
.panel-collapse > .panel-body > .nav li a {
  color: var(--text-details);
}

.job-accordion .panel {
  border: 0;
  border-bottom: 1px solid var(--color-gray-200);
  box-shadow: none;
  margin-bottom: 1.16em;
}

.job-accordion .panel:last-child {
  border-bottom: 0;
  margin: 0;
}

.job-accordion .panel .panel-heading {
  background-color: transparent;
  background-image: none;
  border: 0;
  filter: none;
  padding: 0;
  text-transform: none;
}

.job-accordion .panel .panel-heading a.panel-title {
  color: var(--color-primary);
  font-weight: bold;
}

.job-accordion .panel .panel-heading p {
  font-weight: normal;
}

.job-accordion .panel .panel-body {
  padding: 0.88em 0;
}

.site-sidebar .panel .panel-heading,
.tile-content .panel .panel-heading {
  font-family: var(--subheading-font-family);
  font-size: calc(var(--h3-font-size) - 0.48em);
  margin-top: 0;
  padding-top: 0;
}

.site-sidebar .portlet-team {
  text-align: inherit;
}
