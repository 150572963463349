:root {
  --bs-primary: var(--color-primary);
  --bs-success: var(--color-success);
  --bs-info: var(--color-info);
  --bs-warning: var(--color-warning);
  --bs-danger: var(--color-danger);
  --bs-primary-text-emphasis: var(--color-primary-900);
  --bs-success-text-emphasis: var(--color-success-900);
  --bs-info-text-emphasis: var(--color-info-900);
  --bs-warning-text-emphasis: var(--color-warning-900);
  --bs-danger-text-emphasis: var(--color-danger-900);
  --bs-primary-bg-subtle: var(--color-primary-200);
  --bs-success-bg-subtle: var(--color-success-200);
  --bs-info-bg-subtle: var(--color-info-200);
  --bs-warning-bg-subtle: var(--color-warning-200);
  --bs-danger-bg-subtle: var(--color-danger-200);
  --bs-primary-border-subtle: var(--color-primary-200);
  --bs-success-border-subtle: var(--color-success-200);
  --bs-info-border-subtle: var(--color-info-200);
  --bs-warning-border-subtle: var(--color-warning-200);
  --bs-danger-border-subtle: var(--color-danger-200);
  --bs-body-color: var(--body-font-color);
  --bs-body-bg: var(--body-bg);
  --bs-link-color: var(--link-color);
  --bs-link-decoration: var(--link-text-decoration);
  --bs-link-hover-color: var(--link-color-hover);
  --bs-form-valid-color: var(--color-success);
  --bs-form-valid-border-color: var(--color-success);
  --bs-form-invalid-color: var(--color-danger);
  --bs-form-invalid-border-color: var(--color-danger);
}
