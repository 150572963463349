body.template-edit #fieldset-0 {
  background: transparent;
}

body.template-edit form > div {
  width: 100%;
}

body.template-edit .panel.panel-default .panel-heading {
  background-color: var(--color-primary);
  background-image: none;
  color: var(--color-white);
  filter: none;
}

.formQuestion.label {
  display: block;
  text-align: left;
}

.help-block {
  font-size: 0.88rem;
  letter-spacing: normal;
}

body.template-base_edit .field.error .help-block {
  color: var(--color-danger);
}

.template-edit fieldset legend {
  font-family: var(--body-font-family);
}

#columns-toolbar ul {
  font-family: var(--body-font-family);
}

body.mce-content-body table th {
  font-family: var(--subheading-font-family);
}

body.mce-content-body.drop-cap p:not(.intro):first-child::first-letter {
  font-family: var(--heading-font-family);
}

body.mce-content-body * {
  font-family: var(--body-font-family);
}
