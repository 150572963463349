.haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

@media (min-width: 768px) {
  .haiku-slideshow.slideshow-default-panels .carousel-control-prev,
  .haiku-slideshow.slideshow-default-panels .carousel-control-next,
  .haiku-slideshow.slideshow-default-panels .carousel-indicators {
    display: none;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner {
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner:before,
  .haiku-slideshow.slideshow-default-panels .carousel-inner:after {
    content: " ";
    display: table;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner:after {
    clear: both;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
    background-color: transparent;
    display: block;
    float: left;
    height: 220px;
    margin: 0;
    padding: 0;
    width: 25%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item:nth-child(4) {
    float: right;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.panel-col-2 {
    width: 33.3333333333%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.panel-col-2:nth-child(2),
  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.panel-col-2:last-child {
    float: right;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.panel-col-2.active {
    height: 491px;
    width: 33.3333333333%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image {
    background-color: transparent;
    float: none;
    padding: 0 0.32em 0.32em 0.32em;
    position: static;
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a {
    display: none;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
    color: var(--link-color);
    font-size: 0.88rem;
    font-style: var(--body-font-style);
    font-weight: 600;
    line-height: 1.12em;
    margin: 0.64em 0;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item a {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: 180px;
    overflow: hidden;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image img {
    object-fit: cover;
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active {
    background-color: var(--color-primary);
    float: left;
    height: 391px;
    width: 25%;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active .image {
    display: none;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active .carousel-caption {
    background-color: transparent;
    padding: 4.32em 1.16em;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active .carousel-caption p {
    border-bottom: 0;
    display: block;
  }

  .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active .carousel-caption h2 {
    color: var(--color-white);
    font-family: var(--heading-font-family);
    font-size: var(--h2-font-size);
    font-style: var(--heading-font-style);
    font-weight: var(--heading-font-weight);
    line-height: var(--heading-line-height);
    margin-bottom: var(--heading-bottom-margin);
    margin-top: var(--heading-top-margin);
    text-rendering: var(--heading-text-rendering);
  }
}
