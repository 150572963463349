body.template-croppingeditor .row > .cell {
  float: left;
  min-height: 1px;
  padding-left: 1em;
  padding-right: 0em;
  position: relative;
}

body.template-croppingeditor .row > .cell:first-child {
  width: 33.3333333333%;
}

body.template-croppingeditor .row > .cell:nth-child(2) {
  width: 66.6666666667%;
}
