.nav-style-horizontal .panel-body > .nav {
  display: flex;
  gap: 2em;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nav-style-horizontal .panel-body > .nav > li {
  border-bottom: 0 !important;
  float: none;
}
