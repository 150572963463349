.bg-primary { background-color: var(--color-primary); }
.bg-primary-100 { background-color: var(--color-primary-100); }
.bg-primary-200 { background-color: var(--color-primary-200); }
.bg-primary-300 { background-color: var(--color-primary-300); }
.bg-primary-400 { background-color: var(--color-primary-400); }
.bg-primary-500 { background-color: var(--color-primary-500); }
.bg-primary-600 { background-color: var(--color-primary-600); }
.bg-primary-700 { background-color: var(--color-primary-700); }
.bg-primary-800 { background-color: var(--color-primary-800); }
.bg-primary-900 { background-color: var(--color-primary-900); }

.bg-success { background-color: var(--color-success); }
.bg-success-100 { background-color: var(--color-success-100); }
.bg-success-200 { background-color: var(--color-success-200); }
.bg-success-300 { background-color: var(--color-success-300); }
.bg-success-400 { background-color: var(--color-success-400); }
.bg-success-500 { background-color: var(--color-success-500); }
.bg-success-600 { background-color: var(--color-success-600); }
.bg-success-700 { background-color: var(--color-success-700); }
.bg-success-800 { background-color: var(--color-success-800); }
.bg-success-900 { background-color: var(--color-success-900); }

.bg-info { background-color: var(--color-info); }
.bg-info-100 { background-color: var(--color-info-100); }
.bg-info-200 { background-color: var(--color-info-200); }
.bg-info-300 { background-color: var(--color-info-300); }
.bg-info-400 { background-color: var(--color-info-400); }
.bg-info-500 { background-color: var(--color-info-500); }
.bg-info-600 { background-color: var(--color-info-600); }
.bg-info-700 { background-color: var(--color-info-700); }
.bg-info-800 { background-color: var(--color-info-800); }
.bg-info-900 { background-color: var(--color-info-900); }

.bg-warning { background-color: var(--color-warning); }
.bg-warning-100 { background-color: var(--color-warning-100); }
.bg-warning-200 { background-color: var(--color-warning-200); }
.bg-warning-300 { background-color: var(--color-warning-300); }
.bg-warning-400 { background-color: var(--color-warning-400); }
.bg-warning-500 { background-color: var(--color-warning-500); }
.bg-warning-600 { background-color: var(--color-warning-600); }
.bg-warning-700 { background-color: var(--color-warning-700); }
.bg-warning-800 { background-color: var(--color-warning-800); }
.bg-warning-900 { background-color: var(--color-warning-900); }

.bg-danger { background-color: var(--color-danger); }
.bg-danger-100 { background-color: var(--color-danger-100); }
.bg-danger-200 { background-color: var(--color-danger-200); }
.bg-danger-300 { background-color: var(--color-danger-300); }
.bg-danger-400 { background-color: var(--color-danger-400); }
.bg-danger-500 { background-color: var(--color-danger-500); }
.bg-danger-600 { background-color: var(--color-danger-600); }
.bg-danger-700 { background-color: var(--color-danger-700); }
.bg-danger-800 { background-color: var(--color-danger-800); }
.bg-danger-900 { background-color: var(--color-danger-900); }
