.formTabs {
  border-bottom-color: var(--feature-color-darker);
}

.formTabs a {
  color: var(--feature-color-darkest);
}

.formTabs a.selected {
  background-color: var(--feature-color);
}
