#events-filters-switch,
#news-filters-switch {
  margin-bottom: 0.88em;
}

.listing-filters {
  background-color: var(--feature-color);
  border: 1px solid var(--feature-color);
  margin: 0 0 1.16em 0;
  padding: 0.64em 0.64em 0 0.64em;
}

.listing-filters .form-group,
.listing-filters .input-group {
  margin-bottom: 0.64em;
}

.listing-item h4,
.listing-item .media-heading {
  font-family: var(--body-font-family);
}

.listing-filters .btn-secondary {
  background: var(--color-info);
  border-color: var(--color-info);
  color: var(--color-white);
}

.listing-filters .btn-secondary::after {
  border-left-color: var(--color-info);
}

.listing-filters .btn-secondary:hover {
  background: var(--color-info-700);
  border-color: var(--color-info-700);
}

.listing-filters .btn-secondary:hover::after {
  border-left-color: var(--color-info-700);
}
