
#random-haiku {
  margin: 2rem 1.32rem;
}

#random-haiku::before {
  content: "“";
  display: block;
  font-size: 5.12rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -0.32em;
  text-indent: -0.32em;
}
