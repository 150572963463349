/*
  Font styles

  We use different font styles throughout Haiku. Add the class to any `<p>`, `<span>` or `<div>` element.

  .lead                   - A lead text with a higher font size
  .uppercase              - Text is transformed to upper case
  .underline              - Text is underlined
  .text-small             - Text is small
  .text-huge              - Text is huge
  .text-highlight         - Text is highlighted by using a background color to the element
  .text-bold              - Sames as `<strong>`.

  Markup:
  <span class="{{modifier_class}}">Simple text</span>

  Styleguide 2.5
*/

.text-heading, .rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.rich-text h6 {
  border-bottom: 1px solid var(--color-gray-200);
  font-family: var(--subheading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  margin: 0.88em 0;
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
  padding: 0.88em 0;
  text-rendering: var(--heading-text-rendering);
  text-transform: var(--heading-text-transform);
}

.detail,
.details {
  color: var(--subtext-font-color);
  font-size: 0.8rem;
  line-height: 1.24em;
}

blockquote.pullquote {
  border-bottom: solid 1px var(--color-gray-300);
  float: left;
  margin-right: 1.8em;
  width: 40.52288%;
}

blockquote.pullquote span {
  display: block;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  margin-top: 1.12em;
}

.lead {
  font-size: 1rem;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 0.64em;
  padding-bottom: 0.64em;
}

.text-huge {
  font-size: 2.12rem;
  line-height: 3.68em;
}

.text-small {
  font-size: 0.8rem;
}

.text-heading {
  border-bottom: 1px solid var(--color-gray-200);
  font-family: var(--subheading-font-family);
  margin: 0.88em 0;
  padding: 0.88em 0;
  text-transform: uppercase;
}

.text-color {
  color: var(--color-primary);
}

.text-highlight {
  background-color: var(--feature-color);
}

.text-bold {
  font-weight: bold;
}

.page-header {
  border-bottom: var(--heading-border);
}

.page-header h1 {
  line-height: 1.2;
  margin-bottom: var(--heading-bottom-margin);
}

/* Font styles and sizes for cover pages */

.col-md-1 h2,
.col-md-1 .heading-2 {
  font-size: 0.98rem;
}

.col-md-2 h2,
.col-md-2 .heading-2 {
  font-size: 1rem;
}

.col-md-3 h2,
.col-md-3 .heading-2 {
  font-size: 1.04rem;
}

.col-md-4 h2,
.col-md-4 .heading-2 {
  font-size: 1.12rem;
}

.col-md-5 h2,
.col-md-5 .heading-2 {
  font-size: 1.16rem;
}

.col-md-6 h2,
.col-md-6 .heading-2 {
  font-size: 1.2rem;
}

.col-md-7 h2,
.col-md-7 .heading-2 {
  font-size: 1.22rem;
}

.col-md-8 h2,
.col-md-8 .heading-2 {
  font-size: 1.24rem;
}

.col-md-9 h2,
.col-md-9 .heading-2 {
  font-size: 1.26rem;
}

.col-md-10 h2,
.col-md-10 .heading-2 {
  font-size: 1.32rem;
}

.col-md-11 h2,
.col-md-11 .heading-2 {
  font-size: 1.4rem;
}

.col-md-12 h2,
.col-md-12 .heading-2 {
  font-size: 1.48rem;
}
