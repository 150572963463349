.plonepopup {
  background-clip: padding-box;
  background-color: transparent;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.plonepopup .mceTop {
  border-bottom: 1px solid var(--color-gray-300);
  min-height: 1.16em;
}

.plonepopup .mceButton {
  min-height: 25px;
}
