.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hidden {
  display: none;
}

.hidden-xs {
  display: none;
}

.visible-xs {
  display: block;
}

.text-primary {
  color: var(--color-primary);
}

.text-success {
  color: var(--color-success);
}

.text-info {
  color: var(--color-info);
}

.text-warning {
  color: var(--color-warning);
}

.text-danger {
  color: var(--color-danger);
}

.text-gray {
  color: var(--color-gray);
}

@media screen and (min-width: 767px) {
  .visible-xs {
    display: none;
  }
  .hidden-xs {
    display: block;
  }
}
