#cookienotification {
  background: var(--color-primary);
  bottom: 0;
  font-weight: normal;
  height: auto;
  padding: 0.64em 0 0;
  position: fixed;
  width: 100%;
}

#cookienotification p,
#cookienotification a {
  color: var(--color-primary-100);
}

#cookienotification div {
  float: none;
  margin: 0;
  width: 100%;
}

#cookienotification .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

#cookienotification .cookielaw-site {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

#cookienotification .cookielaw-buttons {
  text-align: center;
}

#cookienotification h5 {
  color: var(--body-bg);
}

#cookienotification .cookielaw-buttons a.cookie-continue {
  background: var(--color-white);
  border-radius: var(--base-border-radius);
  color: var(--color-primary);
  color: var(--color-black);
}

#cookienotification .cookielaw-buttons a.cookie-continue:hover {
  background: var(--feature-color);
  text-decoration: none;
}

#cookienotification .cookielaw-buttons a {
  color: var(--color-white);
  display: inline-block;
  text-align: center;
  width: 130px;
}

@media (min-width: 992px){
  #cookienotification .cookielaw-site {
    float: left;
    width: 20%;
  }

  #cookienotification .cookielaw-text {
    float: left;
    width: 50%;
  }

  #cookienotification .cookielaw-buttons {
    float: right;
    width: 30%;
  }}
