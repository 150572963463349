.mm-menu {
  background: var(--color-gray-100) !important;
  border-style: solid;
  border-width: 2px;
}

.mm-menu .mm-search input {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-gray-200) !important;
  padding-left: 0.64em;
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  font-size: 1rem !important;
}

.mm-menu em.mm-counter {
  color: var(--color-gray-500) !important;
}

.mm-menu [class^=contenttype-]::before,
.mm-menu [class*=" contenttype-"]::before {
  content: "";
}

.mm-menu .mm-title,
.mm-menu .mm-listview > li a:not(.mm-next) {
  color: var(--color-primary) !important;
}

.mm-menu .mm-title {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.mm-menu .mm-navbar__title {
  color: var(--color-gray-600) !important;
}

.mm-listitem {
  padding: calc(var(--padding-base-vertical) * 1.5) var(--padding-base-horizontal) !important;
}
