.popover {
  padding: 0;
}

.popover .popover-title {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.popover-header {
  margin-top: 0;
}
