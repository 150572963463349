.plone-management {
  background-color: var(--color-gray-100);
}

.plone-management > .container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  border-radius: 0.32em;
  margin-bottom: 1.16em;
  margin-top: 1.16em;
  padding-bottom: 1.16em;
  padding-top: 1.16em;
}

.plone-management .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: var(--color-gray-200);
}

.plone-management .table td {
  border-color: var(--color-gray-300);
}

.plone-management a {
  color: var(--color-info);
}

.plone-management a:active,
.plone-management a:focus,
.plone-management a:hover {
  color: var(--color-info-700);
}
