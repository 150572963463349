.site-sidebar .publication-lead {
  border-bottom: 1px solid var(--color-gray-400);
  font-family: var(--subheading-font-family);
  font-size: 0.88rem;
  margin-bottom: 0.32em;
  padding-bottom: 0.32em;
  text-transform: uppercase;
}

.site-sidebar .publication-lead + p {
  margin-bottom: 1.8em;
}

.site-sidebar .listing-filters select {
  width: 100%;
}
