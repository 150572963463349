/*
  Portlets

  The portlets used throughout the site

  Styleguide 9

  Default

  The default portlet. Just add the class `.portlet` to a `<div>` element.

  Markup:
  <div class="portlet">
   <p>Content</p>
  </div>

  Styleguide 9.1
*/

.portlet-team .panel > .panel-heading {
  background-color: transparent;
  background-image: none;
  filter: none;
  font-family: var(--subheading-font-family);
  font-size: calc(var(--h3-font-size) - 0.48em);
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
  padding: 0.64em 0;
  text-rendering: var(--heading-text-rendering);
  text-transform: uppercase;
}

.portlet .nav,
.portlet-team .panel .panel-body .nav {
  margin: 0;
}

.portlet .nav > li.active > a,
.portlet-team .panel .panel-body .nav > li.active > a {
  background-color: transparent;
  color: var(--body-font-color);
}

.portlet .nav > li > a,
.portlet-team .panel .panel-body .nav > li > a {
  padding: 0.64em 0;
}

.portlet .nav > li > a:hover,
.portlet-team .panel .panel-body .nav > li > a:hover,
.portlet .nav > li > a:focus,
.portlet-team .panel .panel-body .nav > li > a:focus {
  background-color: transparent;
  text-decoration: var(--link-text-decoration);
}

.portlet-team .panel .panel-body > .nav > li {
  border-bottom: 1px solid var(--color-gray-200);
}

.portlet-team .panel .panel-body > .nav > li:last-child {
  border-bottom: 0;
}

.portlet-team .panel {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.portlet-team .panel .panel-body {
  margin: 0;
  padding: 0;
}

.portlet-team .panel .panel-body > .nav > li > a {
  font-weight: bold;
}

.portlet blockquote {
  background: var(--feature-color);
  border-color: var(--color-primary);
  border-left: 3px solid var(--color-gray);
  margin: 0.64em 0 4.6em 0;
  padding: 0.7857142857em 1.5em;
}

.portlet blockquote > h2,
.portlet blockquote > .heading-2 {
  border-bottom: 1px solid var(--color-gray-600);
  font-size: 0.88rem;
  padding-bottom: 0.32em;
  text-transform: var(--heading-text-transform);
}

.portlet blockquote p {
  font-size: 0.88rem;
  line-height: 1.12em;
  margin: 0.64em 0;
}

.portlet > h3,
.portlet > .heading-3 {
  font-family: var(--subheading-font-family);
  font-size: calc(var(--h3-font-size) - 0.48em);
  margin-top: 0;
  padding-bottom: 0.64em;
  text-transform: var(--heading-text-transform);
}

.portlet .panel-heading h3,
.portlet .panel-heading .heading-3 {
  border-bottom: none;
  padding-bottom: 0;
}

.page-header .portlet {
  margin-bottom: 0;
}

.portlet-team {
  margin-bottom: 3.32em;
  padding-bottom: 0.64em;
  padding-top: 0.64em;
  text-align: center;
}

.portlet-team .medium-list {
  font-family: var(--body-font-family);
}

.portlet-team .medium-list li {
  text-align: left;
}

.portlet-team .medium-list li h5,
.portlet-team .medium-list li .heading-5 {
  font-size: 0.88rem;
}

.portlet-team .medium-list li img {
  margin-right: 0;
  max-width: 50px;
}

.portlet-team .btn-up.disabled,
.portlet-team .btn-down.disabled {
  color: var(--color-gray-200);
}

/*
  Quick facts

  Deprecated: The quickfacts portlet. Just add the class `.portlet-quickfacts` to a `<div>` element with the class `.portlet`. We should stop using this in favor of box classes.

  Markup:
  <div class="portlet portlet-quickfacts">
   <p>Content</p>
  </div>

  Styleguide 9.2
*/

.portlet-quickfacts p {
  border-bottom: 1px solid var(--color-gray-200);
  margin: 0;
  padding: 0.32em 0;
}

.portlet-quickfacts p:last-child {
  border-bottom: 0;
}

.portlet-quickfacts p b {
  color: var(--color-primary);
  font-family: var(--subheading-font-family);
  font-size: var(--h4-font-size);
  font-weight: 300;
}

.site-sidebar .portlet:first-child {
  margin-top: 0;
}

.portlet-similaritems ul.list-bordered li {
  padding: 0 0 0.5em 0;
}

.shadow-bottom {
  box-shadow: 0 -10px 10px -10px var(--color-gray-200) inset;
  height: 15px;
  margin-top: -60px;
  position: relative;
  z-index: 2;
}

.site-sidebar .portlet h3,
.site-sidebar .portlet .heading-3 {
  border-bottom: 1px solid var(--color-gray-200);
  font-size: 1rem;
  margin-bottom: 0.64em;
}

.site-sidebar .portlet ul {
  margin-bottom: 0;
  margin-top: 0;
}

/*
  Navigation

  Deprecated: The quickfacts portlet. Just add the class `.navigation-portlet` to a `<div>` element with the class `.portlet`. We should stop using this in favor of box classes.

  Markup:
  <div class="portlet navigation-portlet">
   <p>Content</p>
  </div>

  Styleguide 9.3
*/

.navigation-portlet {
  text-align: right;
}

.navigation-portlet > p a {
  color: var(--color-primary);
  font-family: var(--subheading-font-family);
  font-size: var(--h4-font-size);
  font-weight: 300;
}

.navigation-portlet > ul > li {
  border-bottom: 1px solid var(--color-gray-200);
  margin-top: 0.64em;
}

.navigation-portlet > ul > li:last-child {
  border-bottom: 0;
}

.navigation-portlet > ul > li > ul > li {
  display: none;
}

.navigation-portlet > ul > li > ul > li > a {
  color: var(--subtext-font-color);
}

.nav-style-accordion > .panel-body > ul > li > a {
  display: inline-block;
  width: 90%;
}

.nav-style-accordion > .panel-body > ul > li > a + a {
  float: right;
  width: 10%;
}

.nav-style-accordion > .panel-body > ul > li > ul > li {
  display: none;
}
