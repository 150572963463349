/*
  Unstyled list

  A simple unstyled list

  Markup:
   <ul class="list-unstyled">
     <li>Item 1</li>
     <li>Item 2</li>
     <li>Item 3</li>
   </ul>

  Styleguide 7.3
*/

ul.listing li a + p,
ul.list-bordered li p {
  color: var(--subtext-font-color);
  font-size: 0.64rem;
  line-height: 0.88em;
}

ul.list-bordered li {
  border-bottom: 1px solid var(--color-gray-200);
  border-bottom: 1px solid var(--color-gray-200);
  display: block;
  overflow: hidden;
  padding: 0.5em 0;
}

ul.list-bordered li:last-child {
  border-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.list-unstyled {
  margin-left: 0;
  padding-left: 0;
}

.list-unstyled li {
  margin: 0;
  padding: 0;
}

/*
  Media list

  Deprecated: A media list used for building various types of components (like blog comments, Tweets, etc) that feature a left- or right-aligned image alongside textual content. This should not be used and instead we need to start using the regular bootstrap media objects. Examples on the [getbootstrap.com](http://getbootstrap.com/components/#media) site.

  Markup:
   <ul class="media-list">
     <li>Item 1</li>
     <li>Item 2</li>
     <li>Item 3</li>
   </ul>

  Styleguide 7.4
*/

ul.media-list {
  font-size: 0.88rem;
  line-height: 1em;
  list-style: none outside none;
  margin: 0.88em 0;
  padding: 0;
}

ul.media-list li {
  border-bottom: 1px solid var(--color-gray-200);
  display: block;
  overflow: hidden;
  padding: 0.5em 0;
}

ul.media-list li:before, ul.media-list li:after {
  content: " ";
  display: table;
}

ul.media-list li:after {
  clear: both;
}

ul.media-list li:first-child {
  padding-top: 0;
}

ul.media-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

ul.media-list li img:not([width]) {
  float: left;
  margin-right: 0.88em;
  width: 12em;
}

ul.media-list.media-list-small > li.media .listing-item {
  margin: 0;
  padding: 0;
}

ul.media-list li.media:before, ul.media-list li.media:after {
  content: " ";
  display: table;
}

ul.media-list li.media:after {
  clear: both;
}

ul.media-list li.media img:not([width]) {
  float: none;
  margin-right: 0;
  width: 100%;
}

ul.media-list li.media .media-image {
  margin-bottom: 10px;
}

ul.media-list li.media .media-heading {
  font-family: var(--body-font-family);
  font-size: 0.88rem;
  font-weight: 600;
  text-transform: none;
}

ul.media-list li.media .media-body a[class*=state-] {
  font-weight: 600;
}

/*
  List sizes

  Use the sizes to have bigger or smaller spacing between `li` items as well as having smaller/bigger font sizes.

  .small-list           -  A smaller list
  .medium-list          -  A medium list

  Markup:
   <ul class="{{modifier_class}}">
     <li>Item 1</li>
     <li>Item 2</li>
     <li>Item 3</li>
   </ul>

  Styleguide 7.5
*/

ul.small-list {
  font-size: 0.64rem;
}

ul.small-list > li {
  border: none;
  margin: 0;
  padding: 0;
}

ul.small-list li.active a {
  color: var(--color-primary);
  font-weight: bold;
}

ul.small-list a {
  color: var(--text-details);
}

ul.medium-list {
  font-size: 0.88rem;
  line-height: 1em;
  list-style: none outside none;
  margin: 0.88em 0;
  padding: 0;
}

ul.medium-list li {
  border-bottom: 1px solid var(--color-gray-200);
  display: block;
  overflow: hidden;
  padding: 0.5em 0;
  text-align: right;
}

ul.medium-list li:before, ul.medium-list li:after {
  content: " ";
  display: table;
}

ul.medium-list li:after {
  clear: both;
}

ul.medium-list li:first-child {
  padding-top: 0;
}

ul.medium-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

ul.medium-list li img {
  float: left;
  margin-right: 0.88em;
  width: 6em;
}

ul.medium-list li a {
  display: block;
  font-weight: 600;
}

ul.medium-list li span.detail {
  color: var(--link-color);
  font-size: 0.8rem;
}

/*
  List styles

  Use the styles to apply different styles to individual `li` items within the list.

  .list-bordered        -  A list with borders between items
  .list-spaced-big      -  A big gap between items

  Markup:
   <ul class="{{modifier_class}}">
     <li>Item 1</li>
     <li>Item 2</li>
     <li>Item 3</li>
   </ul>

  Styleguide 7.6
*/

ul.list-bordered {
  font-size: 0.88rem;
  line-height: 1em;
  list-style: none outside none;
  margin: 0.88em 0;
  padding: 0;
}

ul.list-bordered li:before, ul.list-bordered li:after {
  content: " ";
  display: table;
}

ul.list-bordered li:after {
  clear: both;
}

ul.list-bordered li:first-child {
  padding-top: 0;
}

ul.list-bordered li img {
  float: left;
  margin-right: 0.88em;
  width: 6em;
}

ul.list-bordered li p {
  color: var(--text-details);
  margin: 0;
}

ul.listing {
  font-size: 0.88rem;
  line-height: 1em;
  list-style: none outside none;
  margin: 0.88em 0;
  margin-left: 0;
  padding: 0;
}

ul.listing li {
  border-bottom: 1px solid var(--color-gray-200);
  display: block;
  overflow: hidden;
  padding: 0.5em 0;
}

ul.listing li:before, ul.listing li:after {
  content: " ";
  display: table;
}

ul.listing li:after {
  clear: both;
}

ul.listing li:first-child {
  padding-top: 0;
}

ul.listing li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

ul.listing li img {
  float: left;
  margin-right: 0.88em;
  width: 6em;
}

ul.listing li a {
  font-weight: 600;
}

ul.list-spaced li {
  margin-right: 15px;
}

ul.list-spaced-big li {
  margin-right: 30px;
}

.nav.nav-tabs {
  margin-bottom: 0.64em;
  margin-left: 0;
}

.listing-item {
  border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 0.64em;
  margin-top: 0.64em;
  padding-bottom: 0.64em;
  padding-top: 0.64em;
}

.listing-item .categories-list a,
.listing-item .categories-list a:hover {
  text-decoration: none;
}

.listing-item h4 a {
  text-decoration: none;
}

.listing-item .media-heading {
  font-size: 0.88rem;
  font-weight: 600;
  text-transform: none;
}

.listing-item h2 {
  line-height: 1;
  margin: 0;
  padding: 0;
}

.listing-item h2 a {
  font-size: var(--h6-font-size);
  line-height: 1.4;
  text-decoration: none;
}

.listing-item a:not(.btn):hover {
  text-decoration: underline;
}

/*
  List layouts

  Use the styles to apply different layouts to the list itself.
  This affects the individual `li` items within the list.

  .list-horizontal        -  A horizontal list with all the items next to each other
  .list-vertical          -  A vertical list with all the items stacked
  .list-scrolling         -  A scrolling list

  Markup:
   <ul class="{{modifier_class}}">
     <li>Item 1</li>
     <li>Item 2</li>
     <li>Item 3</li>
   </ul>

  Styleguide 7.7
*/

ul.list-horizontal {
  align-items: start;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  white-space: normal;
  will-change: transform;
  z-index: 1;
}

ul.list-horizontal li {
  border-bottom: 0;
}

ul.list-horizontal li.media {
  flex: 0 0 100%;
  margin: 0.48em;
  padding: 0.48em;
}

ul.list-horizontal li.media:first-child {
  margin: 0.48em 0.48em 0.48em 0;
  padding: 0.48em 0.48em 0.48em 0;
}

ul.list-horizontal li.media:last-child {
  margin: 0.48em 0 0.48em 0.48em;
  padding: 0.48em 0 0.48em 0.48em;
}

.list-scrolling__wrapper {
  overflow: hidden;
  position: relative;
}

.list-scroll__control {
  align-items: center;
  background-color: rgb(from var(--color-primary) r g b / calc(alpha - 0.2));
  color: var(--color-white) !important;
  display: flex;
  height: 100%;
  padding: 0.32em;
  position: absolute;
  top: 0;
  z-index: 2;
}

.list-scroll__control:hover {
  background-color: var(--color-primary);
  color: var(--color-white) !important;
}

.list-scroll__control.list-scroll__control--left {
  left: 0;
}

.list-scroll__control.list-scroll__control--right {
  right: 0;
}

ul.formTabs {
  background: var(--feature-color);
}

ul.formTabs li {
  color: var(--feature-color-darkester);
}

ul.formTabs.selected {
  background: var(--color-primary);
}

ul.formTabs.selected::after {
  border-color: var(--color-primary) transparent transparent transparent;
}

@media (min-width: 768px){
  ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-1 ul.list-horizontal li.media,
  .column.col-md-1 ul.list-horizontal li.media {
    flex: 0 0 100%;
  }

  .column.col-sm-2 ul.list-horizontal li.media,
  .column.col-md-2 ul.list-horizontal li.media {
    flex: 0 0 100%;
  }

  .column.col-sm-3 ul.list-horizontal li.media,
  .column.col-md-3 ul.list-horizontal li.media {
    flex: 0 0 100%;
  }

  .column.col-sm-4 ul.list-horizontal li.media,
  .column.col-md-4 ul.list-horizontal li.media {
    flex: 0 0 100%;
  }

  .column.col-sm-5 ul.list-horizontal li.media,
  .column.col-md-5 ul.list-horizontal li.media {
    flex: 0 0 100%;
  }}

@media (min-width: 768px) and (min-width: 768px){
  .column.col-sm-6 ul.list-horizontal li.media,
  .column.col-md-6 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-7 ul.list-horizontal li.media,
  .column.col-md-7 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-8 ul.list-horizontal li.media,
  .column.col-md-8 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-9 ul.list-horizontal li.media,
  .column.col-md-9 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-10 ul.list-horizontal li.media,
  .column.col-md-10 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-11 ul.list-horizontal li.media,
  .column.col-md-11 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }

  .column.col-sm-12 ul.list-horizontal li.media,
  .column.col-md-12 ul.list-horizontal li.media {
    flex: 0 0 33%;
  }}
