/* Table of contents */
#toc {
  margin: 0;
  padding: 0;
}
#toc ul {
  margin: 0;
  padding: 0;
}
#toc li {
  list-style: none inside none;
  padding: 0;
}
#toc > li {
  margin: 0.64em 0 0.98em;
  padding-left: 0.48em;
}
#toc > li > a {
  font-weight: bold;
}
#toc > li > ul {
  margin-top: -0.48em;
}
#toc > li > ul > li {
  padding-left: 0.64em;
}
#toc > li > ul > li > ul > li {
  padding-left: 0.98em;
}
