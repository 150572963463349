body.mce-content-body h1,
body.mce-content-body h2,
body.mce-content-body h3,
body.mce-content-body h4,
body.mce-content-body h5,
body.mce-content-body h6 {
  border-bottom: 1px solid var(--color-gray-200);
  font-family: var(--subheading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  margin: 0.88em 0;
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
  padding: 0.88em 0;
  text-rendering: var(--heading-text-rendering);
  text-transform: var(--heading-text-transform);
}

.mce-content-body a {
  color: var(--color-primary) !important;
}

body.mce-content-body h1 {
  font-size: calc(var(--h1-font-size) / 2);
}

body.mce-content-body h2 {
  font-size: calc(var(--h2-font-size) / 2);
}

body.mce-content-body h3 {
  font-size: calc(var(--h3-font-size) / 1.5);
}

body.mce-content-body h4 {
  font-size: calc(var(--h4-font-size) / 1.5);
}

body.mce-content-body h5 {
  font-size: calc(var(--h5-font-size) / 1.5);
}

body.mce-content-body h6 {
  font-size: calc(var(--h6-font-size) / 1.5);
}

body.mce-content-body blockquote {
  color: var(--color-primary);
}

body.mce-content-body blockquote::before,
body.mce-content-body blockquote span,
body.mce-content-body .callout {
  color: var(--color-primary);
}

body.mce-content-body p > span.box-featured {
  background-color: var(--feature-color);
}

.ploneSkin table.mceLayout,
.ploneSkin table.mceLayout:hover,
.plonepopup {
  border-radius: var(--base-border-radius);
}

.ploneSkin table.mceLayout .mceIframeContainer {
  border-radius: 0 0 var(--base-border-radius) var(--base-border-radius);
}

.ploneSkin table.mceLayout .mceFirst td.mceToolbar {
  border-radius: var(--base-border-radius) var(--base-border-radius) 0 0;
}

.ploneSkin .mceMenuItemTitle a {
  font-family: var(--body-font-family) !important;
}

.ploneSkin .mce_h3 span.mceText {
  font-family: var(--heading-font-family) !important;
  text-transform: var(--heading-text-transform) !important;
}

.ploneSkin .mce_h4 span.mceText {
  font-family: var(--subheading-font-family) !important;
  text-transform: var(--heading-text-transform) !important;
}
