.haiku-slideshow {
  --carousel-control-icon-size: 3.2rem;
  margin-bottom: 30px;
}

.haiku-slideshow .carousel-control-prev:focus,
.haiku-slideshow .carousel-control-prev:active,
.haiku-slideshow .carousel-control-next:focus,
.haiku-slideshow .carousel-control-next:active {
  outline: none;
}

.haiku-slideshow .carousel-control-prev,
.haiku-slideshow .carousel-control-next {
  background-color: var(--color-primary);
  border-radius: 50%;
  bottom: 240px;
  height: var(--carousel-control-icon-size);
  opacity: 1;
  padding: calc(var(--carousel-control-icon-size) * 0.25);
  top: 0;
  width: var(--carousel-control-icon-size);
  z-index: 99;
}

.haiku-slideshow .carousel-control-prev .glyphicon,
.haiku-slideshow .carousel-control-next .glyphicon {
  border: 1px solid var(--color-primary);
}

.haiku-slideshow .carousel-control-prev {
  left: 0;
}

.haiku-slideshow .carousel-control-next {
  right: 0;
}

.haiku-slideshow .carousel-indicators {
  bottom: -40px;
  justify-content: flex-start;
  left: 0;
  margin-left: 0;
}

.haiku-slideshow .carousel-indicators button {
  background: var(--color-primary);
  border-bottom: 0;
  border-radius: 6px;
  border-top: 0;
  height: 6px;
  margin: 2px;
  padding: 0;
  width: 6px;
}

.haiku-slideshow .carousel-indicators button.active {
  background: var(--color-primary-700);
  height: 6px;
  margin: 2px;
  width: 6px;
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
  padding: 3.32em;
  position: static;
  text-align: left;
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.haiku-slideshow .carousel-inner .carousel-item .carousel-caption p {
  color: var(--color-white);
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2 {
  font-size: 1.12rem;
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2 span {
  display: block;
  font-size: 1.12rem;
  line-height: 1;
  text-transform: uppercase;
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption p {
  border-bottom: 1px solid var(--color-white);
  font-size: 0.8rem;
  line-height: 1.5;
  margin-bottom: 0.32em;
  margin-top: 0.88em;
  padding-bottom: 0.32em;
}

.haiku-slideshow .carousel-inner .carousel-item .carousel-caption a.btn {
  margin-top: 1.32em;
}

.haiku-slideshow .carousel-inner .carousel-item .image {
  background-color: var(--color-primary);
}

.haiku-slideshow .carousel-inner .carousel-item .image img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

.haiku-slideshow .carousel-inner .carousel-item .copyright {
  color: var(--color-white);
  font-size: 0.64rem;
  padding: 0 0.64em;
}

.haiku-slideshow .btn-secondary {
  background: var(--color-white);
  border-bottom-left-radius: var(--base-border-radius);
  border-bottom-right-radius: 0;
  border-color: var(--color-white);
  border-top-left-radius: var(--base-border-radius);
  border-top-right-radius: 0;
  color: var(--theme-button-color);
  font-size: 0.8rem;
  margin: 0.64em 0;
  padding-right: 1em;
  position: relative;
  text-shadow: none;
  transition: all 0.3s ease;
}

.haiku-slideshow .btn-secondary::after {
  border-bottom: 1.34em solid transparent;
  border-left-color: var(--color-white);
  content: "";
  position: absolute;
  top: 48.5%;
  transition: all 0.3s ease;
  z-index: 2;
}

.haiku-slideshow .btn-secondary:hover {
  background: var(--color-white);
  border-bottom-left-radius: var(--base-border-radius);
  border-bottom-right-radius: 0;
  border-color: var(--color-white);
  border-top-left-radius: var(--base-border-radius);
  border-top-right-radius: 0;
  color: var(--color-primary);
  font-size: 0.8rem;
  margin: 0.64em 0;
  padding-right: 1em;
  position: relative;
  text-shadow: none;
}

.haiku-slideshow .btn-secondary:hover::after {
  z-index: 2;
}

.haiku-slideshow .btn-secondary:hover {
  background: var(--feature-color);
  border-color: var(--feature-color);
  color: var(--color-primary);
}

.haiku-slideshow .btn-secondary:hover::after {
  border-left-color: var(--feature-color);
}

@media (min-width: 768px) {
  .haiku-slideshow .carousel-inner .carousel-item .carousel-caption {
    position: absolute;
  }

  .haiku-slideshow .carousel-control-prev,
  .haiku-slideshow .carousel-control-next {
    bottom: 0;
    top: calc(50% - (var(--carousel-control-icon-size) * 0.5));
  }

  .haiku-slideshow .carousel-control-prev {
    left: calc(-1 * var(--carousel-control-icon-size) * 1.5);
    right: inherit;
  }

  .haiku-slideshow .carousel-control-next {
    right: calc(-1 * var(--carousel-control-icon-size) * 1.5);
  }

  .haiku-slideshow .carousel-control-prev .glyphicon,
  .haiku-slideshow .carousel-control-next .glyphicon {
    border: 0;
  }

  .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.bg-theme .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow .carousel-inner .carousel-item .image .copyright.inverted {
    background-color: var(--color-primary);
  }
}

@media (min-width: 767px) {
  .haiku-slideshow .carousel-inner .carousel-item .copyright {
    padding: 0.64em;
  }
}
