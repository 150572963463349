h1,
.heading-1 {
  font-size: var(--h1-font-size);
}

h2,
.heading-2 {
  font-size: var(--h2-font-size);
}

h3,
.heading-3 {
  font-size: var(--h3-font-size);
}

h4,
.heading-4 {
  font-size: var(--h4-font-size);
}

h5,
.heading-5 {
  font-size: var(--h5-font-size);
}

h6,
.heading-6 {
  font-size: var(--h6-font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4em;
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  line-height: 1.4em;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  color: var(--heading-color);
  font-family: var(--heading-font-family);
  font-style: var(--heading-font-style);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
  text-rendering: var(--heading-text-rendering);
}

.details,
.detail {
  color: var(--text-details);
}

.rich-text h1 {
  font-size: calc(var(--h1-font-size) / 1.4);
}

.rich-text h2 {
  font-size: calc(var(--h2-font-size) / 1.4);
}

.rich-text h3 {
  font-size: calc(var(--h3-font-size) / 1.2);
}

.rich-text h4 {
  font-size: calc(var(--h4-font-size) / 1.2);
}

.rich-text h5 {
  font-size: calc(var(--h5-font-size) / 1.2);
}

.rich-text h6 {
  font-size: calc(var(--h6-font-size) / 1.2);
}

a {
  color: var(--link-color);
  line-height: inherit;
  text-decoration: var(--link-text-decoration);
  transition: all 0.3s ease;
}

a:not(.btn):not(.link-button):hover,
a:not(.btn):not(.link-button):focus {
  color: var(--link-color-hover);
  text-decoration: var(--link-text-decoration-hover);
}

a img {
  border: none;
}

a:focus {
  outline: 2px dotted var(--link-color-hover);
  outline-offset: 2px !important;
}

a:focus:not(:focus-visible) {
  outline: none;
  outline-offset: inherit;
}

a:focus-visible {
  background-color: var(--feature-color);
  outline: 2px dotted var(--link-color-hover);
  outline-offset: 2px !important;
  text-decoration: none;
}

button:focus {
  outline: 2px dotted var(--link-color-hover);
  outline-offset: 2px !important;
}

button:focus:not(:focus-visible) {
  outline: auto;
  outline-offset: inherit;
}

button:focus-visible {
  outline: 2px dotted var(--link-color-hover);
  outline-offset: 2px !important;
}

p,
li {
  color: var(--body-font-color);
  line-height: 1.64em;
  margin: 0.64em 0;
}

p,
li,
th,
td {
  font-size: 0.88rem;
}

blockquote {
  border-bottom: 1px solid var(--color-gray-400);
  border-left: 0;
  color: var(--color-primary);
  display: block;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.24em;
  margin: 1.24em 0.88em 0.88em 0;
  padding-bottom: 0.88em;
  quotes: none;
}

blockquote::before {
  content: "“";
  display: block;
  font-size: 5.12rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -0.32em;
  text-indent: -0.32em;
}

blockquote p {
  line-height: 1.5;
}

blockquote span {
  color: var(--color-primary);
  font-family: var(--body-font-family);
}

blockquote p:last-child > span {
  font-size: 0.88rem;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 767px){
  h1,
  .heading-1 {
    font-size: calc(var(--h1-font-size) / 2);
  }
  h2,
  .heading-2 {
    font-size: calc(var(--h2-font-size) / 2);
  }}
