#site-footer .social-following a,
.social-following a,
.portlet-social-follow a {
  margin: 0.48em;
  text-decoration: none;
}

#site-footer .social-following a:first-child,
.social-following a:first-child,
.portlet-social-follow a:first-child {
  margin-left: 0;
}

#site-footer .social-following a:last-child,
.social-following a:last-child,
.portlet-social-follow a:last-child {
  margin-right: 0;
}

.social-following a:hover,
.social-following a:active,
.social-following a:focus,
.portlet-social-follow a:hover,
.portlet-social-follow a:active,
.portlet-social-follow a:focus {
  text-decoration: none !important;
}

#site-footer .social-following {
  margin: 0.64em 0;
  padding: 0.64em;
  text-align: center;
}

/* Overwrite the twitter with the twitter bird */

.social-following .glyphicon-twitter::before,
.social-sharing .glyphicon-twitter::before {
  content: '\e859';
}

#site-header .social-following {
  margin-right: 140px;
  margin-top: 10px;
}

.portlet.portlet-social-follow h3,
.portlet.portlet-social-follow .heading-3 {
  font-size: 1rem;
  margin-bottom: 0.64em;
}

.portlet.portlet-social-follow .item-actions {
  text-align: left;
}

.social-sharing a:hover {
  text-decoration: none;
}

.social-sharing a.btn-facebook {
  background-color: #fff;
  border-color: #3b5998;
  color: #3b5998;
  color: #3b5998 !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-facebook:hover,
.social-sharing a.btn-facebook:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #3b5998;
  color: #3b5998 !important;
}

.social-sharing a.btn-facebook i {
  background-color: #fff;
  border-radius: 0;
  color: #3b5998;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-flickr {
  background-color: #fff;
  border-color: #0063db;
  color: #0063db;
  color: #0063db !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-flickr:hover,
.social-sharing a.btn-flickr:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #0063db;
  color: #0063db !important;
}

.social-sharing a.btn-flickr i {
  background-color: #fff;
  border-radius: 0;
  color: #0063db;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-linkedin {
  background-color: #fff;
  border-color: #007bb6;
  color: #007bb6;
  color: #007bb6 !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-linkedin:hover,
.social-sharing a.btn-linkedin:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #007bb6;
  color: #007bb6 !important;
}

.social-sharing a.btn-linkedin i {
  background-color: #fff;
  border-radius: 0;
  color: #007bb6;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-soundcloud {
  background-color: #fff;
  border-color: #c95100;
  color: #c95100;
  color: #c95100 !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-soundcloud:hover,
.social-sharing a.btn-soundcloud:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #c95100;
  color: #c95100 !important;
}

.social-sharing a.btn-soundcloud i {
  background-color: #fff;
  border-radius: 0;
  color: #c95100;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-twitter {
  background-color: var(--color-white);
  border-color: var(--color-black);
  color: var(--color-black);
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-twitter:hover,
.social-sharing a.btn-twitter:focus {
  background-color: var(--color-white);
  border-color: var(--color-gray-800);
  color: var(--color-black);
}

.social-sharing a.btn-twitter i {
  background-color: var(--color-white);
  border-radius: 0;
  color: var(--color-black);
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-vimeo {
  background-color: #fff;
  border-color: #1a2e3b;
  color: #1a2e3b;
  color: #1a2e3b !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-vimeo:hover,
.social-sharing a.btn-vimeo:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #1a2e3b;
  color: #1a2e3b !important;
}

.social-sharing a.btn-vimeo i {
  background-color: #fff;
  border-radius: 0;
  color: #1a2e3b;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-youtube {
  background-color: #fff;
  border-color: #b00;
  color: #b00;
  color: #b00 !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-youtube:hover,
.social-sharing a.btn-youtube:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #b00;
  color: #b00 !important;
}

.social-sharing a.btn-youtube i {
  background-color: #fff;
  border-radius: 0;
  color: #b00;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}

.social-sharing a.btn-instagram {
  background-color: #fff;
  border-color: #e80070;
  color: #e80070;
  color: #e80070 !important;
  text-shadow: none;
  transition: all 0.3s ease;
}

.social-sharing a.btn-instagram:hover,
.social-sharing a.btn-instagram:focus {
  background-color: #fff;
  border-color: var(--link-color-hover);
  color: #e80070;
  color: #e80070 !important;
}

.social-sharing a.btn-instagram i {
  background-color: #fff;
  border-radius: 0;
  color: #e80070;
  font-size: 0.8rem;
  height: auto;
  line-height: 1;
  width: auto;
}
