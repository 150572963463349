body.mce-content-body blockquote span,
body.mce-content-body .callout span,
.rich-text blockquote span,
.rich-text .callout span {
  color: var(--subtext-font-color);
  display: block;
  font-family: Arial,
  sans-serif;
  font-size: 0.64rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.88em;
  margin-top: 1.12em;
}

.rich-text p.intro,
body.mce-content-body p.intro {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.12em;
  margin-bottom: 1.12em;
}

body.smaller.mce-content-body p,
body.smaller.mce-content-body li,
.smaller.rich-text p,
.smaller.rich-text li {
  color: #444;
  font-size: 0.64rem;
  line-height: 0.88em;
}

.hard-left.rich-text ol,
body.hard-left.mce-content-body ol,
.hard-left.rich-text ul,
body.hard-left.mce-content-body ul {
  margin-left: 0;
  padding-left: 1.5em;
}

.rich-text h3,
body.mce-content-body h3 {
  margin-top: 1.12em;
}

.rich-text h3:first-child,
body.mce-content-body h3:first-child {
  border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 0.64em;
  margin-top: 0;
  padding-bottom: 0.64em;
  padding-top: 0;
}

.rich-text h4,
body.mce-content-body h4 {
  margin-top: 1.2em;
}

.rich-text ol,
body.mce-content-body ol,
.rich-text ul,
body.mce-content-body ul {
  margin-bottom: 1em;
  padding-left: 1.16em;
}

.rich-text ol li,
body.mce-content-body ol li,
.rich-text ul li,
body.mce-content-body ul li {
  padding: 0 0.64em;
}

.rich-text ol li ul,
body.mce-content-body ol li ul,
.rich-text ol li ol,
body.mce-content-body ol li ol,
.rich-text ul li ul,
body.mce-content-body ul li ul,
.rich-text ul li ol,
body.mce-content-body ul li ol {
  margin-bottom: 0;
  margin-top: 0.8em;
}

.rich-text ol li ul > li:first-child,
body.mce-content-body ol li ul > li:first-child,
.rich-text ol li ol > li:first-child,
body.mce-content-body ol li ol > li:first-child,
.rich-text ul li ul > li:first-child,
body.mce-content-body ul li ul > li:first-child,
.rich-text ul li ol > li:first-child,
body.mce-content-body ul li ol > li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.rich-text ol li ul > li:last-child,
body.mce-content-body ol li ul > li:last-child,
.rich-text ol li ol > li:last-child,
body.mce-content-body ol li ol > li:last-child,
.rich-text ul li ul > li:last-child,
body.mce-content-body ul li ul > li:last-child,
.rich-text ul li ol > li:last-child,
body.mce-content-body ul li ol > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.rich-text ul,
body.mce-content-body ul {
  list-style-type: square;
}

.rich-text ol,
body.mce-content-body ol {
  list-style-type: decimal;
}

.rich-text ol ol,
body.mce-content-body ol ol {
  list-style-type: lower-latin;
}

.rich-text ol ol ol,
body.mce-content-body ol ol ol {
  list-style-type: lower-roman;
}

.rich-text a:not(.btn),
body.mce-content-body a:not(.btn) {
  font-weight: bold;
}

.rich-text .image-left,
body.mce-content-body .image-left,
.rich-text .image-right,
body.mce-content-body .image-right {
  margin: 0.5em 0;
}

.rich-text .image-left:first-child,
body.mce-content-body .image-left:first-child,
.rich-text .image-right:first-child,
body.mce-content-body .image-right:first-child {
  margin-top: 0.3em;
}

.rich-text .image-left,
body.mce-content-body .image-left {
  float: left;
  margin-right: 1.5em;
}

.rich-text .image-right,
body.mce-content-body .image-right {
  float: right;
  margin-left: 1.5em;
}

.rich-text .image-inline,
body.mce-content-body .image-inline {
  display: inline-block;
  margin: 0.32em;
  vertical-align: bottom;
}

.rich-text .image-center,
body.mce-content-body .image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.rich-text span.figure,
body.mce-content-body span.figure {
  position: relative;
}

.rich-text span.figure small + span.image-caption,
body.mce-content-body span.figure small + span.image-caption {
  padding-bottom: 1.24em;
}

.rich-text figure.image-center,
body.mce-content-body figure.image-center {
  width: 100%;
}

.rich-text figure.image-center .image-container,
body.mce-content-body figure.image-center .image-container {
  display: inline-block;
}

.rich-text figure.image-center small + figcaption,
body.mce-content-body figure.image-center small + figcaption {
  padding-bottom: 1.24em;
}

.rich-text blockquote,
body.mce-content-body blockquote,
.rich-text .callout,
body.mce-content-body .callout {
  border-bottom: var(--color-gray-600);
  display: block;
  float: left;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.28em;
  margin: 1.12em 0.88em 1.12em 0;
  padding-bottom: 1.12em;
}

.rich-text blockquote::before,
body.mce-content-body blockquote::before,
.rich-text .callout::before,
body.mce-content-body .callout::before {
  content: "“";
  display: block;
  font-size: 4.88rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -0.32em;
  text-indent: -0.32em;
}

.rich-text .callout,
body.mce-content-body .callout {
  float: none;
  text-align: center;
}

.rich-text p > span.box-featured,
body.mce-content-body p > span.box-featured {
  display: block;
  padding: 0.64em 1.16em;
}

.rich-text table,
body.mce-content-body table {
  margin-bottom: var(--line-height-computed);
  width: 100%;
}

.rich-text table:not(.table-simple-gallery) > thead > tr,
body.mce-content-body table:not(.table-simple-gallery) > thead > tr,
.rich-text table:not(.table-simple-gallery) > tbody > tr,
body.mce-content-body table:not(.table-simple-gallery) > tbody > tr,
.rich-text table:not(.table-simple-gallery) > tfoot > tr,
body.mce-content-body table:not(.table-simple-gallery) > tfoot > tr {
  border-bottom: 1px solid var(--color-gray-300);
}

.rich-text table > thead > tr:last-of-type,
body.mce-content-body table > thead > tr:last-of-type,
.rich-text table > tbody > tr:last-of-type,
body.mce-content-body table > tbody > tr:last-of-type,
.rich-text table > tfoot > tr:last-of-type,
body.mce-content-body table > tfoot > tr:last-of-type {
  border-bottom: none;
}

.rich-text table > thead > tr > th,
body.mce-content-body table > thead > tr > th,
.rich-text table > thead > tr > td,
body.mce-content-body table > thead > tr > td,
.rich-text table > tbody > tr > th,
body.mce-content-body table > tbody > tr > th,
.rich-text table > tbody > tr > td,
body.mce-content-body table > tbody > tr > td,
.rich-text table > tfoot > tr > th,
body.mce-content-body table > tfoot > tr > th,
.rich-text table > tfoot > tr > td,
body.mce-content-body table > tfoot > tr > td {
  line-height: var(--line-height-base);
  padding: var(--table-cell-padding);
  vertical-align: top;
}

.rich-text table > thead > tr > th,
body.mce-content-body table > thead > tr > th {
  border-bottom: 2px solid var(--color-gray-300);
  vertical-align: bottom;
}

.rich-text table > caption + thead > tr:first-child > th,
body.mce-content-body table > caption + thead > tr:first-child > th,
.rich-text table > caption + thead > tr:first-child > td,
body.mce-content-body table > caption + thead > tr:first-child > td,
.rich-text table > colgroup + thead > tr:first-child > th,
body.mce-content-body table > colgroup + thead > tr:first-child > th,
.rich-text table > colgroup + thead > tr:first-child > td,
body.mce-content-body table > colgroup + thead > tr:first-child > td,
.rich-text table > thead:first-child > tr:first-child > th,
body.mce-content-body table > thead:first-child > tr:first-child > th,
.rich-text table > thead:first-child > tr:first-child > td,
body.mce-content-body table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.rich-text table > tbody + tbody,
body.mce-content-body table > tbody + tbody {
  border-top: 2px solid var(--color-gray-300);
}

.rich-text table th,
body.mce-content-body table th {
  border-bottom: var(--color-gray-200);
  font-size: 1rem;
  font-weight: 500;
}

.rich-text table td,
body.mce-content-body table td,
.rich-text table th,
body.mce-content-body table th {
  padding: 0.25em 0.5em 0.25em 0;
}

.rich-text table .table,
body.mce-content-body table .table {
  background-color: var(--body-bg);
}

.drop-cap.rich-text p:not(.intro):first-child::first-letter,
body.drop-cap.mce-content-body p:not(.intro):first-child::first-letter {
  float: left;
  font-size: 3.68rem;
  margin-right: 0.32em;
  margin-top: 0.88em;
}

body.mce-content-body {
  background: var(--color-white);
  box-sizing: border-box;
  padding: 0.8em 1.2em;
}

body.mce-content-body blockquote {
  float: none;
  margin: 3em 0 2em;
  width: 100%;
}

body.mce-content-body blockquote span {
  color: var(--text-details);
  font-family: var(--body-font-family);
}

body.mce-content-body table th {
  font-family: var(--subheading-font-family);
}

body.mce-content-body.drop-cap p:not(.intro):first-child::first-letter {
  font-family: var(--heading-font-family);
}

.rich-text a:not(.btn) {
  color: var(--link-color);
}

.rich-text blockquote {
  color: var(--color-primary);
}

.rich-text blockquote::before {
  color: var(--color-primary);
}

.rich-text blockquote.pullquote {
  border-bottom: 1px solid var(--color-gray-300);
  float: left;
  line-height: 1.5;
  margin-right: 1.88em;
  width: 40.52288%;
}

.rich-text blockquote span {
  color: var(--color-primary);
  font-family: var(--body-font-family);
}

.rich-text blockquote p {
  line-height: 1.5;
}

.rich-text blockquote p:last-child > span {
  font-size: 0.88rem;
  font-style: normal;
  font-weight: 600;
}

.rich-text table th {
  font-family: var(--subheading-font-family);
}

.rich-text.drop-cap p:not(.intro):first-child::first-letter {
  font-family: var(--heading-font-family);
}

.rich-text .table tr {
  background-color: transparent;
}

.rich-text .table.table-striped tbody tr:nth-child(odd) {
  background: var(--feature-color);
}

.rich-text .callout {
  color: var(--color-primary);
}

.rich-text p > span.box-featured {
  background-color: var(--feature-color);
}

.no-flexboxlegacy .rich-text .table {
  table-layout: fixed;
}
