/*
  Tables

  For light styling add the class `.table` to any `<table>`. Full documentation available at [getbootstrap.com](http://getbootstrap.com/css/#tables).

  Styleguide 3
*/

/* Fix the In and out widget min-height */

table.ordered-selection-field td select {
  min-height: 200px;
}

/*
  Table styles

  Add any of the following classes to the `.table` base class.

  .table-striped   - Adds zebra-striping to any table row within the `<tbody>` via the `:nth-child` CSS selector.
  .table-bordered  - Add borders and rounded corners to the table.
  .table-hover     - Enable a hover state on table rows within a `<tbody>`.
  .table-condensed - Makes tables more compact by cutting cell padding in half.
  .table-invisible - Removes all colors and borders from the table.

  Markup:
  <table class="table {{modifier_class}}">
    <thead>
      <tr>
        <th>#</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Username</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Bogdan</td>
        <td>Lazar</td>
        <td>@tricinel</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Lukas</td>
        <td>Zdych</td>
        <td>@lzdych</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Radim</td>
        <td>Novotny</td>
        <td>@radim</td>
      </tr>
    </tbody>
  </table>

  Styleguide 3.1
*/

.table.table-invisible tr {
  background-color: transparent;
  border-bottom: none;
}

.table.table-invisible tr > td,
.table.table-invisible tr > th {
  border-top: 0;
}
