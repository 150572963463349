#events-calendar .fc-toolbar .fc-left,
#events-calendar .fc-toolbar .fc-right {
  margin-top: 0.9em;
}

#events-calendar .fc-view .table-bordered .fc-head-container,
#events-calendar .fc-view .table-bordered .fc-day-header,
#events-calendar .fc table,
#events-calendar .table-bordered > .fc-body td {
  border: 0;
}

#events-calendar .fc-day-grid .table-bordered .fc-day {
  border-right-color: var(--color-gray-100);
  border-right-style: solid;
  border-right-width: 1px;
}

#events-calendar .fc-day-grid .table-bordered .fc-day:last-child {
  border-right: 0;
}

#events-calendar .fc-body .fc-row {
  border-bottom-color: var(--color-gray-100);
  border-bottom-style: solid;
}

#events-calendar .fc-body .fc-row:last-child {
  border-bottom-width: 0;
}

#events-calendar .fc-view .table-bordered .fc-day-header {
  border-bottom-color: var(--color-primary);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  font-size: 0.8rem;
  padding-bottom: 0.64em;
  padding-top: 0.64em;
}

#events-calendar .fc-day-top {
  text-align: center;
}

#events-calendar .fc-day-top .fc-day-number {
  color: var(--color-gray-600);
  display: block;
  float: none !important;
  font-size: 0.8rem;
  padding: 0.64em 0.08em;
}

#events-calendar .fc-event, #events-calendar .fc-event-dot {
  background-color: var(--color-primary);
}

#events-calendar .fc-event {
  border-color: var(--color-primary);
}

#events-calendar .fc-today {
  background-color: var(--color-primary-200);
  color: var(--color-primary);
}
