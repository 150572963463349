/*
  Icons

  The icons used throughout the site

  Styleguide 10
*/

/*
  Haiku Glyphicons

  The glyphicons used throughout Haiku. Add any of the following classes to the 'i' element.

  .glyphicon-search                          - search
  .glyphicon-chevron-down                    - chevron-down
  .glyphicon-chevron-left                    - chevron-left
  .glyphicon-chevron-right                   - chevron-right
  .glyphicon-chevron-up                      - chevron-up
  .glyphicon-home                            - home
  .glyphicon-rss                             - rss
  .glyphicon-calendar                        - calendar
  .glyphicon-twitter                         - twitter
  .glyphicon-facebook                        - facebook
  .glyphicon-flickr                          - flickr
  .glyphicon-youtube                         - youtube
  .glyphicon-soundcloud                      - soundcloud
  .glyphicon-user-add                        - user-add
  .glyphicon-remove                          - remove
  .glyphicon-plus                            - plus
  .glyphicon-instagram                       - instagram
  .glyphicon-minus                           - minus
  .glyphicon-fontsize                        - fontsize
  .glyphicon-picture                         - picture
  .glyphicon-vcard                           - vcard
  .glyphicon-download                        - download
  .glyphicon-star                            - star
  .glyphicon-star-empty                      - star-empty
  .glyphicon-blogger                         - blogger
  .glyphicon-move                            - move
  .glyphicon-lock                            - lock
  .glyphicon-lock-open                       - lock-open
  .glyphicon-underline                       - underline
  .glyphicon-subscript                       - subscript
  .glyphicon-superscript                     - superscript
  .glyphicon-list-bullet                     - list-bullet
  .glyphicon-list-numbered                   - list-numbered
  .glyphicon-link                            - link
  .glyphicon-unlink                          - unlink
  .glyphicon-code                            - code
  .glyphicon-quote                           - quote
  .glyphicon-bold                            - bold
  .glyphicon-italic                          - italic
  .glyphicon-sort                            - sort
  .glyphicon-sort-down                       - sort-down
  .glyphicon-sort-up                         - sort-up
  .glyphicon-envelope                        - envelope
  .glyphicon-trash                           - trash
  .glyphicon-edit                            - edit
  .glyphicon-cog                             - cog
  .glyphicon-vimeo                           - vimeo
  .glyphicon-globe                           - globe
  .glyphicon-align-left                      - align-left
  .glyphicon-align-center                    - align-center
  .glyphicon-align-right                     - align-right
  .glyphicon-align-justify                   - align-justify
  .glyphicon-resize-small                    - resize-small
  .glyphicon-resize-full                     - resize-full
  .glyphicon-download-alt                    - download-alt
  .glyphicon-ok                              - ok
  .glyphicon-info-sign                       - info-sign
  .glyphicon-exclamation-sign                - exclamation-sign
  .glyphicon-phone                           - phone
  .glyphicon-spin                            - spin
  .glyphicon-camera                          - camera
  .glyphicon-linkedin                        - linkedin
  .glyphicon-orcid                           - ORCiD
  .glyphicon-orcid-color                     - ORCiD color
  .glyphicon-blog                            - blog
  .glyphicon-h2-tag                          - h2-tag
  .glyphicon-h1-tag                          - h1-tag
  .glyphicon-paragraph-tag                   - paragraph-tag
  .glyphicon-file                            - file
  .glyphicon-file-word                       - file-word
  .glyphicon-bug                             - bug
  .glyphicon-award                           - award
  .glyphicon-thumbs-up                       - thumbs-up
  .glyphicon-thumbs-down                     - thumbs-down
  .glyphicon-video                           - video
  .glyphicon-audio                           - audio
  .glyphicon-table-insert-column-before      - table-insert-column-before
  .glyphicon-table-insert-new                - table-insert-new
  .glyphicon-table-insert-row-after          - table-insert-row-after
  .glyphicon-table-insert-row-before         - table-insert-row-before
  .glyphicon-table-merge-cells               - table-merge-cells
  .glyphicon-table-remove-column             - table-remove-column
  .glyphicon-table-remove-row                - table-remove-row
  .glyphicon-table-split-merge-cells         - table-split-merge-cells
  .glyphicon-table-insert-column-after       - table-insert-column-after
  .glyphicon-menu                            - menu
  .glyphicon-chart-pie                       - chart-pie
  .glyphicon-history                         - history
  .glyphicon-filters                         - filters
  .glyphicon-indent-left                     - outdent text
  .glyphicon-indent-right                    - indent text

  Markup:
   <i class="{{modifier_class}}"></i>

  Styleguide 10.1
*/

[class^='glyphicon-']::before,
[class*=' glyphicon-']::before,
[class^='contenttype-icon-']::before,
[class*=' contenttype-icon-']::before,
[class^='mimetype-icon-']::before,
[class*=' mimetype-icon-']::before {
  display: inline-block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

/* Haiku icons font */

@font-face {
  font-family: 'haiku-font';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/haiku/haiku-font.eot?78793146');
  src: url('./fonts/haiku/haiku-font.eot?78793146#iefix')
      format('embedded-opentype'),
    url('./fonts/haiku/haiku-font.woff?78793146') format('woff'),
    url('./fonts/haiku/haiku-font.ttf?78793146') format('truetype'),
    url('./fonts/haiku/haiku-font.svg?78793146#haiku-font') format('svg');
}

[class^='glyphicon-']::before,
[class*=' glyphicon-']::before {
  font-family: 'haiku-font';
}

.glyphicon-search::before {
  content: '\e800';
}

.glyphicon-chevron-down::before {
  content: '\e801';
}

.glyphicon-chevron-left::before {
  content: '\e802';
}

.glyphicon-chevron-right::before {
  content: '\e803';
}

.glyphicon-chevron-up::before {
  content: '\e804';
}

.glyphicon-home::before {
  content: '\e805';
}

.glyphicon-rss::before {
  content: '\e806';
}

.glyphicon-calendar::before {
  content: '\e807';
}

.glyphicon-facebook::before {
  content: '\e80a';
}

.glyphicon-flickr::before {
  content: '\e80b';
}

.glyphicon-youtube::before {
  content: '\e80c';
}

.glyphicon-soundcloud::before {
  content: '\e80d';
}

.glyphicon-user-add::before {
  content: '\e80e';
}

.glyphicon-remove::before {
  content: '\e80f';
}

.glyphicon-plus::before {
  content: '\e810';
}

.glyphicon-minus::before {
  content: '\e812';
}

.glyphicon-fontsize::before {
  content: '\e813';
}

.glyphicon-picture::before {
  content: '\e814';
}

.glyphicon-vcard::before {
  content: '\e815';
}

.glyphicon-download::before {
  content: '\e816';
}

.glyphicon-star::before {
  content: '\e817';
}

.glyphicon-star-empty::before {
  content: '\e818';
}

.glyphicon-blogger::before {
  content: '\e819';
}

.glyphicon-move::before {
  content: '\e81a';
}

.glyphicon-lock::before {
  content: '\e81b';
}

.glyphicon-lock-open::before {
  content: '\e81c';
}

.glyphicon-underline::before {
  content: '\e81d';
}

.glyphicon-subscript::before {
  content: '\e81e';
}

.glyphicon-superscript::before {
  content: '\e81f';
}

.glyphicon-list-bullet::before {
  content: '\e820';
}

.glyphicon-list-numbered::before {
  content: '\e821';
}

.glyphicon-link::before {
  content: '\e822';
}

.glyphicon-unlink::before {
  content: '\e823';
}

.glyphicon-code::before {
  content: '\e824';
}

.glyphicon-quote::before {
  content: '\e825';
}

.glyphicon-bold::before {
  content: '\e826';
}

.glyphicon-italic::before {
  content: '\e827';
}

.glyphicon-sort::before {
  content: '\e828';
}

.glyphicon-sort-down::before {
  content: '\e829';
}

.glyphicon-sort-up::before {
  content: '\e82a';
}

.glyphicon-envelope::before {
  content: '\e82b';
}

.glyphicon-trash::before {
  content: '\e82c';
}

.glyphicon-edit::before {
  content: '\e82d';
}

.glyphicon-cog::before {
  content: '\e82e';
}

.glyphicon-vimeo::before {
  content: '\e82f';
}

.glyphicon-globe::before {
  content: '\e830';
}

.glyphicon-align-left::before {
  content: '\e831';
}

.glyphicon-align-center::before {
  content: '\e832';
}

.glyphicon-align-right::before {
  content: '\e833';
}

.glyphicon-align-justify::before {
  content: '\e834';
}

.glyphicon-resize-small::before {
  content: '\e835';
}

.glyphicon-resize-full::before {
  content: '\e836';
}

.glyphicon-download-alt::before {
  content: '\e837';
}

.glyphicon-ok::before {
  content: '\e838';
}

.glyphicon-info-sign::before {
  content: '\e839';
}

.glyphicon-exclamation-sign::before {
  content: '\e83a';
}

.glyphicon-phone::before {
  content: '\e83b';
}

.glyphicon-spin::before {
  content: '\e83c';
}

.glyphicon-camera::before {
  content: '\e83d';
}

.glyphicon-linkedin::before {
  content: '\e83e';
}

.glyphicon-blog::before {
  content: '\e83f';
}

.glyphicon-h2-tag::before {
  content: '\e840';
}

.glyphicon-h1-tag::before {
  content: '\e841';
}

.glyphicon-paragraph-tag::before {
  content: '\e842';
}

.glyphicon-file::before {
  content: '\e843';
}

.glyphicon-file-word::before {
  content: '\e844';
}

.glyphicon-bug::before {
  content: '\e845';
}

.glyphicon-award::before {
  content: '\e846';
}

.glyphicon-thumbs-up::before {
  content: '\e847';
}

.glyphicon-thumbs-down::before {
  content: '\e848';
}

.glyphicon-video::before {
  content: '\e849';
}

.glyphicon-audio::before {
  content: '\e84a';
}

.glyphicon-table-insert-column-before::before {
  content: '\e84c';
}

.glyphicon-table-insert-new::before {
  content: '\e84d';
}

.glyphicon-table-insert-row-after::before {
  content: '\e84e';
}

.glyphicon-table-insert-row-before::before {
  content: '\e84f';
}

.glyphicon-table-merge-cells::before {
  content: '\e850';
}

.glyphicon-table-remove-column::before {
  content: '\e851';
}

.glyphicon-table-remove-row::before {
  content: '\e852';
}

.glyphicon-table-split-merge-cells::before {
  content: '\e853';
}

.glyphicon-table-insert-column-after::before {
  content: '\e811';
}

.glyphicon-menu::before {
  content: '\e854';
}

.glyphicon-chart-pie::before {
  content: '\f200';
}

.glyphicon-history::before {
  content: '\e855';
}

.glyphicon-filters::before {
  content: '\f0b0';
}

.glyphicon-indent-left::before {
  content: '\e856';
}

.glyphicon-indent-right::before {
  content: '\e857';
}

.glyphicon-instagram:before {
  content: '\f16d';
}

.glyphicon-orcid:before {
  content: '\e84b';
}

.glyphicon-orcidid-color:before {
  content: '\e858';
}

.glyphicon-twitter:before {
  content: '\e859';
}

/*
  Haiku Content Type Icons

  The content type icons used throughout Haiku. Add any of the following classes to the 'i' element.

  .contenttype-icon-attachment                         - attachment
  .contenttype-icon-course                            - course
  .contenttype-icon-department                         - department
  .contenttype-icon-divisionpage                       - divisionpage
  .contenttype-icon-founderpage                        - founderpage
  .contenttype-icon-newsitem                           - newsitem
  .contenttype-icon-haiku-profile                        - haiku-profile
  .contenttype-icon-publication                        - publication
  .contenttype-icon-researchgroup                      - researchgroup
  .contenttype-icon-researchtheme                      - researchtheme
  .contenttype-icon-studentship                        - studentship
  .contenttype-icon-turnkeyevent                       - turnkeyevent
  .contenttype-icon-turnkeylink                        - turnkeylink
  .contenttype-icon-turnkeypage                        - turnkeypage
  .contenttype-icon-turnkeysection                     - turnkeysection
  .contenttype-icon-volunteer                          - volunteer
  .contenttype-icon-turnkeyimage                       - turnkeyimage
  .contenttype-icon-haiku-coverpage                    - haiku-coverpage
  .contenttype-icon-haiku-faq                          - haiku-faq
  .contenttype-icon-formfolder                         - formfolder
  .contenttype-icon-haiku-taglist                      - haiku-taglist
  .contenttype-icon-haiku-project                      - haiku-project
  .contenttype-icon-blogpost                           - blogpost

  Markup:
   <i class="{{modifier_class}}"></i>

  Styleguide 10.2
*/

/* Contenttype icons font */

@font-face {
  font-family: 'haiku-contenttype';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/contenttype/haiku-contenttype.eot?28338996');
  src: url('./fonts/contenttype/haiku-contenttype.eot?28338996#iefix')
      format('embedded-opentype'),
    url('./fonts/contenttype/haiku-contenttype.woff?28338996') format('woff'),
    url('./fonts/contenttype/haiku-contenttype.woff2?64607250') format('woff2'),
    url('./fonts/contenttype/haiku-contenttype.ttf?28338996')
      format('truetype'),
    url('./fonts/contenttype/haiku-contenttype.svg?28338996#haiku-contenttype')
      format('svg');
}

[class^='contenttype-icon-']::before,
[class*=' contenttype-icon-']::before {
  font-family: 'haiku-contenttype';
}

.contenttype-icon-attachment::before {
  content: '\e800';
}

.contenttype-icon-course::before {
  content: '\e804';
}

.contenttype-icon-department::before {
  content: '\e805';
}

.contenttype-icon-divisionpage::before {
  content: '\e806';
}

.contenttype-icon-founderpage::before {
  content: '\e807';
}

.contenttype-icon-newsitem::before {
  content: '\e809';
}

.contenttype-icon-haiku-profile::before {
  content: '\e80a';
}

.contenttype-icon-publication::before {
  content: '\e80b';
}

.contenttype-icon-researchgroup::before {
  content: '\e80c';
}

.contenttype-icon-researchtheme::before {
  content: '\e80d';
}

.contenttype-icon-studentship::before {
  content: '\e80f';
}

.contenttype-icon-turnkeyevent::before {
  content: '\e810';
}

.contenttype-icon-turnkeylink::before {
  content: '\e811';
}

.contenttype-icon-turnkeypage::before {
  content: '\e812';
}

.contenttype-icon-turnkeysection::before {
  content: '\e813';
}

.contenttype-icon-volunteer::before {
  content: '\e820';
}

.contenttype-icon-turnkeyimage::before {
  content: '\e821';
}

.contenttype-icon-haiku-coverpage::before {
  content: '\e822';
}

.contenttype-icon-haiku-faq::before {
  content: '\e823';
}

.contenttype-icon-formfolder::before {
  content: '\e824';
}

.contenttype-icon-haiku-taglist::before {
  content: '\e825';
}

.contenttype-icon-haiku-project::before {
  content: '\f135';
}

.contenttype-icon-blogpost::before {
  content: '\e827';
}

/*
  Haiku Mimetype Icons

  The mimetype icons used throughout Haiku. Add any of the following classes to the 'i' element.

  .mimetype-icon-unknown                            - unknown
  .mimetype-icon-text                               - text
  .mimetype-icon-pdf                                - pdf
  .mimetype-icon-doc                                - doc
  .mimetype-icon-xls                                - xls
  .mimetype-icon-ppt                                - ppt
  .mimetype-icon-image                              - image
  .mimetype-icon-archive                            - archive
  .mimetype-icon-audio                              - audio
  .mimetype-icon-video                              - video
  .mimetype-icon-application                        - application
  .mimetype-icon-message                            - message
  .mimetype-icon-sxd                                - OpenOffice Draw drawing
  .mimetype-icon-sxm                                - OpenOffice Math formula

  Markup:
   <i class="{{modifier_class}}"></i>

  Styleguide 10.3
*/

@font-face {
  font-family: 'haiku-mimetypes';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/mimetype/haiku-mimetypes.eot?64345637');
  src: url('./fonts/mimetype/haiku-mimetypes.eot?64345637#iefix')
      format('embedded-opentype'),
    url('./fonts/mimetype/haiku-mimetypes.woff2?64345637') format('woff2'),
    url('./fonts/mimetype/haiku-mimetypes.woff?64345637') format('woff'),
    url('./fonts/mimetype/haiku-mimetypes.ttf?64345637') format('truetype'),
    url('./fonts/mimetype/haiku-mimetypes.svg?64345637#haiku-mimetypes')
      format('svg');
}

[class^='mimetype-icon-']::before,
[class*=' mimetype-icon-']::before {
  font-family: 'haiku-mimetypes';
}

.mimetype-icon-unknown::before {
  content: '\e801';
}

.mimetype-icon-text::before {
  content: '\f0f6';
}

.mimetype-icon-pdf::before {
  content: '\f1c1';
}

.mimetype-icon-doc::before {
  content: '\f1c2';
}

.mimetype-icon-xls::before {
  content: '\f1c3';
}

.mimetype-icon-ppt::before {
  content: '\f1c4';
}

.mimetype-icon-image::before {
  content: '\f1c5';
}

.mimetype-icon-archive::before {
  content: '\f1c6';
}

.mimetype-icon-audio::before {
  content: '\f1c7';
}

.mimetype-icon-video::before {
  content: '\f1c8';
}

.mimetype-icon-application::before {
  content: '\f1c9';
}

.mimetype-icon-message::before {
  content: '\e800';
}

.mimetype-icon-sxd::before {
  content: '\e802';
}

.mimetype-icon-sxm::before {
  content: '\e803';
}
