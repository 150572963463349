#hero,
#below-hero {
  margin-left: -0.98em;
  margin-right: -0.98em;
  padding: 1.48em 0.98em;
  text-align: center;
}

#hero .page-header {
  border-bottom: 0;
}

.hero--center h1,
.hero--center h2,
.hero--center h3,
.hero--center h4,
.hero--center h5,
.hero--center h6,
.hero--center p,
.below-hero--center h1,
.below-hero--center h2,
.below-hero--center h3,
.below-hero--center h4,
.below-hero--center h5,
.below-hero--center h6,
.below-hero--center p {
  color: var(--color-white);
}

#hero.hero--center {
  background-color: var(--color-primary);
}

#hero-image.hero-image--overlap {
  margin-top: -40px;
}

#hero-image.hero-image--overlap .image-container img {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 4px;
  padding: 0.32em;
}

#hero-image.hero-image--overlap figcaption {
  text-align: center;
}

.hero--center .text-summary {
  margin: 1rem 0;
}
