@media (min-width: 992px) {
  /* Full width */
  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item {
    height: 391px;
    overflow: hidden;
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
    padding: 1.16em 1.16em 2.5em 1.16em;
    width: 100%;
    z-index: 3;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption p {
    border-bottom: 0;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image {
    height: 391px;
    padding: 0;
    width: 100%;
    z-index: 2;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image img {
    height: auto;
    overflow: hidden;
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright {
    margin: 0;
    position: absolute;
    z-index: 3;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image {
    position: absolute;
    bottom: auto;
    left: 0;
    right: auto;
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
    bottom: auto;
    left: 0;
    right: auto;
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption h2 {
    display: block;
    font-size: 2.32rem;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-top .carousel-inner .carousel-item .carousel-caption {
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-top .carousel-inner .carousel-item .copyright {
    bottom: 0;
    right: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-bottom .carousel-inner .carousel-item .carousel-caption {
    bottom: 0;
    padding: 1.16em;
    top: auto;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-bottom .carousel-inner .carousel-item .copyright {
    right: 0;
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-left .carousel-inner .carousel-item .carousel-caption {
    bottom: 0;
    left: 0;
    max-width: 32%;
    padding: 1.16em;
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-left .carousel-inner .carousel-item .copyright {
    bottom: 0;
    right: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-right .carousel-inner .carousel-item .carousel-caption {
    bottom: 0;
    left: auto;
    max-width: 32%;
    padding: 1.16em;
    right: 0;
    top: 0;
  }

  .haiku-slideshow.slideshow-default-full-width.slideshow-title-position-right .carousel-inner .carousel-item .copyright {
    bottom: 0;
    left: 0;
  }
}
