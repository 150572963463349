.haiku--megamenu {
  --bg-color: var(--color-primary);
  --scroll-color: var(--color-primary-800);

  position: relative;
}

.haiku--megamenu .sr-hidden {
  display: none;
  visibility: hidden;
}

.haiku--megamenu__button {
  background-color: var(--bg-color);
  border: none;
  color: var(--color-white);
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.64em 0.48em;
  position: relative;
  z-index: 10;
}

.haiku--megamenu__menu {
  background-color: var(--bg-color);
  display: none;
  gap: 2rem;
  grid-template-areas:
    'search'
    'main'
    'actions';
  justify-content: space-between;
  margin: 0;
  padding: 2em 4em;
  position: absolute;
  width: 60vw;
  max-width: 800px;
  animation: dropdown 300ms ease-in-out forwards;
  transform-origin: top center;
  z-index: 10;
}

.haiku--megamenu[data-position="left"] .haiku--megamenu__menu {
  right: 0;
}

.haiku--megamenu[data-position="right"] .haiku--megamenu__menu {
  left: 0;
}

.haiku--megamenu__button[aria-expanded="true"] + .haiku--megamenu__menu {
  display: grid;
}

.haiku--megamenu__menu > ul {
  /* flex-basis: 50%; */
  list-style: none;
  margin: 0;
  max-height: 480px;
  overflow-y: auto;
  padding: 0;
}

.haiku--megamenu__menu {
  /* max-width: calc(400px - 10em); */
}

.haiku--megamenu__menu > ul[aria-label="Main navigation"] {
  /* flex-grow: 1; */
  grid-area: main;
}

.haiku--megamenu__menu > ul[aria-label="Site actions"] {
  grid-area: actions;
  /* flex-grow: 0; */
  text-align: left;
}

.haiku--megamenu__search {
  background-color: var(--color-primary-800);
  border: var(--color-primary-900);
  border-radius: 4px;
  color: var(--color-white);
  font-size: 1.12rem;
  padding: 0.64em 0.48em;
  grid-area: search;
}

/* Style the scrollbars */
.haiku--megamenu__menu > ul {
  scrollbar-color: var(--scroll-color) transparent;
  scrollbar-width: thin;
}

.haiku--megamenu__menu > ul::-webkit-scrollbar {
  width: 0.48em;
}

.haiku--megamenu__menu > ul::-webkit-scrollbar-thumb {
   /* Foreground */
  background: var(--scroll-color);
  border-radius: 2em;
  border: 3px solid var(--bg-color);
}

.haiku--megamenu__menu > ul::-webkit-scrollbar-track {
   /* Background */
   background: transparent;
}
/* End style the scrollbars */

.haiku--megamenu__menu li[role="none"] {
  padding: 0.48em;
}

.haiku--megamenu__menu a[role="menuitem"] {
  color: var(--color-white);
  font-size: 1.2rem;
  text-decoration: none;
}

.haiku--megamenu__menu--inline {
  align-items: center;
  display: flex;
  gap: 2em;
  justify-content: flex-start;
  list-style: none;
  margin: 1em 0 0 0;
  padding: 1em 0 0 0;
}

.haiku--megamenu__submenu {
  list-style: none;
}

/* Style the icons */
.haiku--megamenu__menu--inline [class^="glyphicon-"] {
  color: var(--color-white);
}

.haiku--megamenu__menu--inline [class^="glyphicon-"]:hover,
.haiku--megamenu__menu--inline [class^="glyphicon-"]:focus {
  background-color: var(--scroll-color);
}

@keyframes dropdown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}

@media screen and (min-width: 768px) {
  .haiku--megamenu__menu {
    grid-template-areas:
      'main main search'
      'main main actions';
  }

  .haiku--megamenu__menu > ul[aria-label="Site actions"] {
    text-align: right;
  }

  .haiku--megamenu__menu--inline {
    justify-content: flex-end;
  }
}
