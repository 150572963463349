.haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

@media (min-width: 768px) {
  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .carousel-caption h2 {
    font-size: 1.28rem;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .carousel-caption p {
    font-size: 0.88rem;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .image {
    padding-right: 0;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .image img {
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .carousel-caption {
    display: table;
    float: left;
    position: static;
    width: 33.3333333333%;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .carousel-caption .inner {
    display: table-cell;
    vertical-align: middle;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .image {
    display: block;
    float: right;
    position: relative;
    width: 66.6666666667%;
  }

  .haiku-slideshow.slideshow-default-standard .carousel-inner .carousel-item .copyright {
    bottom: 10px;
    left: 10px;
    position: absolute;
  }
}
