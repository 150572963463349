#haiku-feedback-tab {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 23%;
  white-space: nowrap;
  z-index: 10000;
}

#haiku-feedback-tab a {
  background-color: var(--color-primary);
  border: 2px solid var(--color-white);
  color: var(--color-white);
  display: block;
  font-family: var(--body-font-family);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.9em 2em;
  text-align: center;
  text-decoration: none;
}

#haiku-feedback-tab a:hover {
  background-color: var(--color-primary-700);
}

#haiku-feedback-tab.HaikuFeedbackTabLeft {
  left: -2.1em;
  transform: rotate(-90deg);
}

#haiku-feedback-tab.HaikuFeedbackTabRight {
  right: -2.1em;
  transform: rotate(90deg);
}

#zenbox_tab {
  box-sizing: content-box;
}
