/*
  Boxes

  Showcase different blocks of text inside a box.

  .box-featured        -  Has a background
  .box-plain           -  It's just plain
  .box-border-left     -  The box will have a border on the left side
  .box-border-right    -  The box will have a border on the right side
  .box-border-both     -  The box will have a border on both sides

  Markup:
  <div class="box-plain {{modifier_class}}"><h2>Box title</h2><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque nobis totam deserunt, dolor molestias odio. Similique reprehenderit, nulla cumque deleniti vitae impedit ex? Aperiam sequi commodi incidunt ipsam, provident tempore.</p></div>

  Styleguide 8.2
*/

.box-border-left,
.box-border-right,
.box-border-both {
  border-color: var(--color-primary);
}

.box-featured {
  background-color: var(--feature-color);
}

.box-featured:before,
.box-border-left:before,
.box-border-right:before,
.box-border-both:before,
.box-featured:after,
.box-border-left:after,
.box-border-right:after,
.box-border-both:after {
  content: " ";
  display: table;
}

.box-featured:after,
.box-border-left:after,
.box-border-right:after,
.box-border-both:after {
  clear: both;
}

.box-featured,
.box-border-left,
.box-border-right,
.box-border-both {
  padding: 0.64em 1.16em;
}

.box-plain > h2,
.box-plain > .heading-2 {
  border-bottom: 1px solid var(--color-gray-200);
  font-family: var(--subheading-font-family);
  font-size: calc(var(--h3-font-size) - 0.48em);
  margin-top: 0;
  padding-bottom: 0.64em;
  text-transform: var(--heading-text-transform);
}

.box-border-left {
  border-left: 3px solid var(--color-gray);
  text-align: left;
}

.box-border-right {
  border-right: 3px solid var(--color-gray);
  text-align: right;
}

.box-border-both {
  border-left: 3px solid var(--color-gray);
  border-right: 3px solid var(--color-gray);
  text-align: justify;
}

.box-border-both h1,
.box-border-both h2,
.box-border-both h3,
.box-border-both h4,
.box-border-both h5,
.box-border-both h6 {
  text-align: left;
}

.box-border-none.box-plain {
  padding-top: 0;
}
