.details-date {
  color: var(--color-primary);
  font-family: var(--subheading-font-family);
}

.event-listing .event time > .date-date,
.event-listing .event time > .date-day,
.event-listing .event time > .date-month,
.event-listing .listing-item-event time > .date-date,
.event-listing .listing-item-event time > .date-day,
.event-listing .listing-item-event time > .date-month,
.events-listing .event time > .date-date,
.events-listing .event time > .date-day,
.events-listing .event time > .date-month,
.events-listing .listing-item-event time > .date-date,
.events-listing .listing-item-event time > .date-day,
.events-listing .listing-item-event time > .date-month {
  color: var(--color-primary);
}
