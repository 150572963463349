.research-group,
.research-theme {
  border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 0.64em;
  padding-bottom: 0.64em;
}

.research-group .carousel-caption {
  padding: var(--padding-base-vertical) 0;
  min-height: 4rem;
}

.research-group .carousel-caption a {
  text-decoration: none;
}

.portaltype-researchgroup div[id^=group-col-] .edit-tools a {
  font-family: var(--body-font-family);
}
