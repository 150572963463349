html {
  overflow-x: hidden;
  width: 100vw;
}

body {
  background: var(--body-bg);
  color: var(--body-font-color);
  cursor: var(--cursor-default-value);
  font-family: var(--body-font-family);
  font-size: var(--base-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  line-height: var(--base-line-height);
  margin: 0;
  padding: 0;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

::-moz-selection {
  background: var(--user-text-select-bgcolor);
}

::selection {
  background: var(--user-text-select-bgcolor);
}

span.figure small,
figure small {
  bottom: 0.32em;
  color: var(--color-white);
  font-size: 0.8rem;
  line-height: 1.25em;
  position: absolute;
  right: 0.64em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 9;
}

span.figure span.image-caption,
span.figure figcaption,
figure span.image-caption,
figure figcaption {
  background: var(--color-primary);
  color: var(--color-white);
  display: flex;
  font-size: 0.88rem;
  font-weight: bold;
  padding: 0.48em;
}

span.figure img,
figure img {
  width: 100%;
}

span.figure span.image-caption {
  display: block;
}
