.auto-append > .datagridwidget-manipulator.delete-row img,
.auto-append > .datagridwidget-manipulator.move-up img,
.auto-append > .datagridwidget-manipulator.move-down img,
.auto-append > .datagridwidget-manipulator.insert-row img {
  display: inline-block !important;
}

.datagridwidget-table-view {
  background: transparent;
  border: 0;
}

.datagridwidget-table-view .header {
  background: transparent;
  color: var(--color-primary-500);
  font-size: 1rem;
  padding: 0.64em;
}

.datagridwidget-table-view .datagridwidget-manipulator {
  vertical-align: middle;
}

.datagridwidget-table-view .datagridwidget-cell {
  padding: 1.16em 0.5em;
}

.form-group .datagridwidget-table-view {
  border: 0;
}

.form-group .datagridwidget-table-view,
.form-group .datagridwidget-table-view .header {
  background: transparent;
}

.form-group .datagridwidget-cell input {
  padding: 0.32em;
}

.form-group .datagridwidget-table-view .header {
  padding: 0.32em;
}

.form-group .datagridwidget-table-view .datagridwidget-cell {
  padding: 1.16em 0.32em;
}
