.nav-tabs {
  border-bottom-width: 0;
}

.nav-tabs > li {
  border-bottom: 1px solid var(--color-gray-200);
  float: none;
  margin-bottom: -0.32em;
  padding: 0.32em 0;
}

.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:active {
  text-decoration: none !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  font-weight: bold;
}

.nav-tabs-inverted {
  background-color: var(--color-primary);
  border-bottom: 0;
}

.nav-tabs-inverted li {
  margin: 0;
  padding: 0;
}

.nav-tabs-inverted li > a {
  border: 0;
  border-radius: 0;
  color: var(--color-white);
  margin-right: 0;
}

.nav-tabs-inverted li > a:hover,
.nav-tabs-inverted li > a:focus,
.nav-tabs-inverted li > a:active,
.nav-tabs-inverted li.active > a {
  background-color: var(--color-white) !important;
  border: 0;
  color: var(--color-primary);
}

.nav-tabs-inverted li.active > a,
.nav-tabs-inverted li.active > a:active,
.nav-tabs-inverted li.active > a:focus,
.nav-tabs-inverted li.active > a:hover,
.nav-tabs-inverted li > a:hover,
.nav-tabs-inverted li > a:active,
.nav-tabs-inverted li > a:focus {
  color: var(--color-primary);
}

.nav-tabs-inverted li.active > a > .text-circled,
.nav-tabs-inverted li.active > a:active > .text-circled,
.nav-tabs-inverted li.active > a:focus > .text-circled,
.nav-tabs-inverted li.active > a:hover > .text-circled,
.nav-tabs-inverted li > a:hover > .text-circled,
.nav-tabs-inverted li > a:active > .text-circled,
.nav-tabs-inverted li > a:focus > .text-circled {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.nav-tabs-inverted li.active > a:hover,
.nav-tabs-inverted li.active > a:focus,
.nav-tabs-inverted li.active > a:active {
  border: 0;
  color: var(--color-primary);
  cursor: pointer;
}

.nav-tabs-inverted li > a > .text-circled {
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--color-white);
  display: inline-block;
  height: 1.16em;
  line-height: 1.16em;
  text-align: center;
  width: 1.16em;
}

@media screen and (min-width: 768px){
  .nav-tabs {
    border-bottom-width: 1px;
  }

  .nav-tabs > li {
    border-bottom-width: 0;
    float: left;
  }}
