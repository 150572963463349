.tile table.table,
.profile-modal table.table {
  margin-bottom: 0;
}

.tile table.table tr:first-child td,
.profile-modal table.table tr:first-child td {
  border-top: 0;
}

.tile table.table tr td,
.profile-modal table.table tr td {
  border: 0;
}

.tile table.table tr td:first-child,
.profile-modal table.table tr td:first-child {
  font-weight: 600;
}

.tile table.table tr td.edit-actions,
.profile-modal table.table tr td.edit-actions {
  display: block;
  text-align: right;
  width: 100px;
}

.tile table.table tr td.edit-actions ul,
.profile-modal table.table tr td.edit-actions ul {
  margin: 0;
  padding: 0;
}

.tile table.table tr td.edit-actions ul li,
.profile-modal table.table tr td.edit-actions ul li {
  margin: 0 0.32em;
  padding: 0;
}

.tile table.table tr td.edit-actions a.remove-cross,
.profile-modal table.table tr td.edit-actions a.remove-cross {
  color: var(--color-danger);
  font-size: 1rem;
}

.tile table.table tr td.edit-actions a.remove-cross:hover,
.profile-modal table.table tr td.edit-actions a.remove-cross:hover {
  color: var(--color-danger-600);
  cursor: pointer;
  text-decoration: none;
}

.tile table.table tr.form-group td:first-child,
.profile-modal table.table tr.form-group td:first-child {
  padding-top: 1.16em;
}

.tile > .tile-content:not(.cover-richtext-tile) table.table tr td:first-child,
.profile-modal table.table tr td:first-child {
  color: var(--color-primary);
}

.tile-content.empty {
  display: none;
}

.tile-content.media-container .youtube {
  padding-top: 0;
}

ul.cover-profile-list li {
  text-align: left;
}

.tile h3,
.tile h4,
.tile .heading-3,
.tile .heading-4 {
  font-family: var(--subheading-font-family);
}

.tile.tile-profile h2,
.tile.tile-profile .heading-2 {
  color: var(--color-primary);
  font-family: var(--body-font-family);
  font-size: var(--h6-font-size);
}
