/*
@while $slideshow-max-column-count > 0 {
  .column.col-md-#{$slideshow-max-column-count} > .tile .haiku-slideshow {
    @extend %reset-slideshow-base;

    &.slideshow-default-full-width {
      @extend %reset-slideshow-full-width;

      .carousel-inner .carousel-item .carousel-caption {
        background-color: $theme-color;
      }
    }

    &.slideshow-default-panels {
      @extend %reset-slideshow-panels;

      .carousel-inner .carousel-item {
        background-color: $theme-color;

        .carousel-caption {
          h2 {
            font-family: $header-font-family;
          }
        }
      }
    }

    &.slideshow-default-tableft,
    &.slideshow-default-tabright {
      @extend %reset-slideshow-tabs;
    }
  }

  $slideshow-max-column-count: $slideshow-max-column-count - 1;
}

$i: 0;

@while $i < $coverpage-column-count {
  .column.col-md-#{$i} > .tile .haiku-slideshow {
    &.slideshow-default-standard,
    &.slideshow-default-full-width,
    &.slideshow-default-panels {
      .carousel-control {
        &.left span,
        &.right span {
          border: 1px solid #fff;
        }

        &.left {
          left: -40px;
        }

        &.right {
          right: -40px;
        }
      }
    }
  }

  $i: $i + 1;
}
*/

.column.col-md-1 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow .carousel-control-next {
  top: -80px;
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow .carousel-control-prev {
  left: 0;
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow .carousel-control-next {
  right: 0;
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .image,
.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption {
  float: none;
  width: 100%;
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p {
  color: var(--color-white);
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption h2 {
  font-size: 1.12rem;
}

.column.col-md-1 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-2 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-3 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-4 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-5 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-6 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-7 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-8 > .tile .haiku-slideshow .carousel-inner .carousel-item .carousel-caption p {
  font-size: 0.8rem;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item {
  height: auto;
  overflow: visible;
  width: 100%;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .image {
  height: auto;
  position: static;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .copyright {
  color: var(--color-white);
  padding-left: 0.64em;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-indicators {
  display: block;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  float: none;
  height: auto;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption {
  padding: 2.32em;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  line-height: var(--heading-line-height);
  margin-bottom: var(--heading-bottom-margin);
  margin-top: var(--heading-top-margin);
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .image {
  display: block;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item.active {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 2;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption {
  display: block;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption a {
  display: inline-block;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-indicators {
  display: block;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next {
  top: -80px;
  z-index: 99;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev {
  left: 0;
  right: auto;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-prev > span::before,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-prev > span::before {
  content: "\e802";
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next {
  left: auto;
  right: 0;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-control-next > span::before,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-control-next > span::before {
  content: "\e803";
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner {
  height: auto;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item {
  float: none;
  height: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 2;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption {
  display: block;
  position: static;
  width: 100%;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption {
  height: auto;
  padding: 2.32em;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption h2,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption h2 {
  font-size: 1.12rem;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p {
  display: inline-block;
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-inner .carousel-item .carousel-caption {
  background-color: var(--color-primary);
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item {
  background-color: var(--color-primary);
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-inner .carousel-item .carousel-caption h2 {
  font-family: var(--heading-font-family);
}

.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-0 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-1 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-2 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-3 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-4 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-5 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-6 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-7 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-8 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-9 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-10 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}

.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev span,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next span,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev span,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next span,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev span,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next span {
  border: 1px solid var(--color-white);
}

.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-prev,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-prev,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-prev {
  left: -40px;
}

.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-standard .carousel-control-next,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-full-width .carousel-control-next,
.column.col-md-11 > .tile .haiku-slideshow.slideshow-default-panels .carousel-control-next {
  right: -40px;
}
