.glyphicon-facebook,
.glyphicon-twitter,
.glyphicon-blog,
.glyphicon-instagram,
.glyphicon-linkedin,
.glyphicon-flickr,
.glyphicon-soundcloud,
.glyphicon-vimeo,
.glyphicon-youtube,
.glyphicon-orcid,
.glyphicon-rss,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.glyphicon-calendar {
  font-size: 1rem;
  transition: all 0.3s ease;
}

.glyphicon-facebook,
.glyphicon-twitter,
.glyphicon-blog,
.glyphicon-instagram,
.glyphicon-linkedin,
.glyphicon-flickr,
.glyphicon-soundcloud,
.glyphicon-vimeo,
.glyphicon-youtube,
.glyphicon-orcid,
.glyphicon-rss,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.glyphicon-calendar {
  display: inline-block;
  height: 1.8em;
  line-height: 1.8em;
  text-align: center;
  width: 1.8em;
}

.glyphicon-facebook,
.glyphicon-blog,
.glyphicon-instagram,
.glyphicon-orcid,
.glyphicon-linkedin,
.glyphicon-flickr,
.glyphicon-soundcloud,
.glyphicon-vimeo,
.glyphicon-youtube,
.glyphicon-calendar {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.glyphicon-twitter {
  background-color: transparent;
  color: var(--color-black);
}

.glyphicon-rss {
  background-color: #faa30b;
  color: var(--color-white);
}

.glyphicon-rss:hover,
.glyphicon-rss:active,
.glyphicon-rss:focus {
  background-color: #e29209;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.carousel-control .glyphicon-chevron-left:hover,
.carousel-control .glyphicon-chevron-left:active,
.carousel-control .glyphicon-chevron-left:focus,
.carousel-control .glyphicon-chevron-right:hover,
.carousel-control .glyphicon-chevron-right:active,
.carousel-control .glyphicon-chevron-right:focus {
  background-color: var(--color-primary-700);
}

.glyphicon-calendar:hover,
.glyphicon-calendar:active,
.glyphicon-calendar:focus,
.glyphicon-blog:hover,
.glyphicon-blog:active,
.glyphicon-blog:focus {
  background-color: var(--color-primary);
}

.glyphicon-facebook:hover,
.glyphicon-facebook:active,
.glyphicon-facebook:focus {
  background-color: #3b5998;
}

.glyphicon-flickr:hover,
.glyphicon-flickr:active,
.glyphicon-flickr:focus {
  background-color: #0063db;
}

.glyphicon-instagram:hover,
.glyphicon-instagram:active,
.glyphicon-instagram:focus {
  background-color: #9b1c88;
}

.glyphicon-linkedin:hover,
.glyphicon-linkedin:active,
.glyphicon-linkedin:focus {
  background-color: #007bb6;
}

.glyphicon-soundcloud:hover,
.glyphicon-soundcloud:active,
.glyphicon-soundcloud:focus {
  background-color: #f60;
}

.glyphicon-vimeo:hover,
.glyphicon-vimeo:active,
.glyphicon-vimeo:focus {
  background-color: #aad450;
}

.glyphicon-youtube:hover,
.glyphicon-youtube:active,
.glyphicon-youtube:focus {
  background-color: #b00;
}

.glyphicon-calendar:hover,
.glyphicon-calendar:active,
.glyphicon-calendar:focus {
  background-color: var(--color-gray);
}

.glyphicon-blog:hover,
.glyphicon-blog:active,
.glyphicon-blog:focus {
  background-color: var(--color-gray);
}

.glyphicon-orcid:hover,
.glyphicon-orcid:active,
.glyphicon-orcid:focus {
  background-color: #a6ce39;
}

.social-media-icons {
  margin: 15px 0;
  text-align: center;
}

.social-media-icons .glyphicon-facebook,
.social-media-icons .glyphicon-twitter,
.social-media-icons .glyphicon-blog,
.social-media-icons .glyphicon-instagram,
.social-media-icons .glyphicon-orcid,
.social-media-icons .glyphicon-linkedin,
.social-media-icons .glyphicon-flickr,
.social-media-icons .glyphicon-soundcloud,
.social-media-icons .glyphicon-vimeo,
.social-media-icons .glyphicon-youtube {
  border-radius: 50%;
  display: inline-block;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.social-media-icons li,
.brand-icons li {
  margin: 0 5px !important;
}

.social-media-icons .glyphicon-facebook,
.social-media-icons .glyphicon-twitter,
.social-media-icons .glyphicon-blog,
.social-media-icons .glyphicon-instagram,
.social-media-icons .glyphicon-orcid,
.social-media-icons .glyphicon-linkedin,
.social-media-icons .glyphicon-flickr,
.social-media-icons .glyphicon-soundcloud,
.social-media-icons .glyphicon-vimeo,
.social-media-icons .glyphicon-youtube,
.brand-icons .glyphicon-facebook,
.brand-icons .glyphicon-twitter,
.brand-icons .glyphicon-blog,
.brand-icons .glyphicon-instagram,
.brand-icons .glyphicon-orcid,
.brand-icons .glyphicon-linkedin,
.brand-icons .glyphicon-flickr,
.brand-icons .glyphicon-soundcloud,
.brand-icons .glyphicon-vimeo,
.brand-icons .glyphicon-youtube {
  color: var(--color-white);
}

.brand-icons .glyphicon-twitter,
.social-media-icons .glyphicon-twitter {
  background-color: var(--color-black);
}

.social-media-icons .glyphicon-twitter:hover,
.brand-icons .glyphicon-twitter:hover {
  background-color: var(--color-gray-900);
}

.social-media-icons .glyphicon-facebook,
.brand-icons .glyphicon-facebook {
  background-color: #3b5998;
}

.social-media-icons .glyphicon-facebook:hover,
.brand-icons .glyphicon-facebook:hover {
  background-color: #35508a;
}

.social-media-icons .glyphicon-instagram,
.brand-icons .glyphicon-instagram {
  background-color: #ef0074;
}

.social-media-icons .glyphicon-instagram:hover,
.brand-icons .glyphicon-instagram:hover {
  background-color: #cd0867;
}

.social-media-icons .glyphicon-linkedin,
.brand-icons .glyphicon-linkedin {
  background-color: #0976b4;
}

.social-media-icons .glyphicon-linkedin:hover,
.brand-icons .glyphicon-linkedin:hover {
  background-color: #0a679c;
}

.social-media-icons .glyphicon-flickr,
.brand-icons .glyphicon-flickr {
  background-color: #0063dc;
}

.social-media-icons .glyphicon-flickr:hover,
.brand-icons .glyphicon-flickr:hover {
  background-color: #0259c3;
}

.social-media-icons .glyphicon-soundcloud,
.brand-icons .glyphicon-soundcloud {
  background-color: #f80;
}

.social-media-icons .glyphicon-soundcloud:hover,
.brand-icons .glyphicon-soundcloud:hover {
  background-color: rgb(223, 122, 6);
}

.social-media-icons .glyphicon-vimeo,
.brand-icons .glyphicon-vimeo {
  background-color: #162221;
}

.social-media-icons .glyphicon-vimeo:hover,
.brand-icons .glyphicon-vimeo:hover {
  background-color: #0b1211;
}

.social-media-icons .glyphicon-youtube,
.brand-icons .glyphicon-youtube {
  background-color: #e52d27;
}

.social-media-icons .glyphicon-youtube:hover,
.brand-icons .glyphicon-youtube:hover {
  background-color: #c52722;
}

.social-media-icons .glyphicon-orcid,
.brand-icons .glyphicon-orcid {
  background-color: #a6ce39;
}

.social-media-icons .glyphicon-orcid:hover,
.brand-icons .glyphicon-orcid:hover {
  background-color: #8eaf31;
}

.social-media-icons .glyphicon-blog,
.brand-icons .glyphicon-blog {
  background-color: var(--color-primary);
}

.social-media-icons .glyphicon-blog:hover,
.brand-icons .glyphicon-blog:hover {
  background-color: var(--color-primary-600);
}

a.state-hidden::before {
  content: "\e81b";
  display: inline-block;
  font-family: "haiku-font";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.icon-sm {
  font-size: 0.64rem;
  height: 1.875em;
  line-height: 1.875em;
  text-align: center;
  width: 1.875em;
}

/* Haiku Voting */

.rating-container .rating-stars {
  color: var(--color-primary);
}
