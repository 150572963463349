/*
  Modals

  Modals. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/javascript/#modals)

  Markup:
  <div class="modal fade">
   <div class="modal-dialog">
     <div class="modal-content">
       <div class="modal-header">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
         <h4 class="modal-title">Modal title</h4>
       </div>
       <div class="modal-body">
         <p>One fine body&hellip;</p>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
         <button type="button" class="btn btn-primary">Save changes</button>
       </div>
     </div>
   </div>
  </div>

  Styleguide 8.7
*/

.modal-backdrop.in {
  opacity: 0.02;
}

.modal .modal-dialog:not(.modal-lg) {
  max-width: 760px;
  width: 100%;
}

.modal .modal-dialog {
  padding-top: 60px;
}

.modal .modal-dialog .modal-title {
  font-family: var(--body-font-family);
  font-size: 1.12rem;
}

.modal .modal-dialog .modal-content {
  border-radius: var(--base-border-radius);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.modal .modal-dialog .modal-body {
  max-height: 40em;
  margin: 0.64em 0.64em 0.64em 0;
  overflow-y: auto;
}

.modal .modal-dialog .modal-body::-webkit-scrollbar {
  width: 6px;
}

.modal .modal-dialog .modal-body .modal-sidebar h4 {
  border-bottom: 1px solid var(--color-gray-400);
  font-family: var(--subheading-font-family);
  font-size: 0.88rem;
  margin-bottom: 0.32em;
  padding-bottom: 0.32em;
  text-transform: uppercase;
}

.modal .modal-dialog .modal-body .modal-sidebar h4 + p {
  margin-bottom: 1.88em;
}

.modal .modal-dialog .modal-body .modal-sidebar::-webkit-scrollbar {
  background-color: var(--feature-color);
}

.modal .modal-dialog .modal-body .modal-sidebar::-webkit-scrollbar-track {
  background-color: var(--feature-color);
  border-radius: var(--base-border-radius);
}

.modal .modal-dialog .modal-body .modal-sidebar::-webkit-scrollbar-thumb {
  background-color: var(--feature-color-darkest);
  border-radius: var(--base-border-radius);
}

.profile-modal .modal-header:before, .profile-modal .modal-header:after {
  content: " ";
  display: table;
}

.profile-modal .modal-header:after {
  clear: both;
}

.profile-modal .modal-dialog {
  padding-top: 100px;
}

.profile-modal .modal-dialog .modal-body {
  height: 400px;
}
