.badge {
  font-size: 0.8rem;
  padding: 0.48em 0.64em 0.32em;
  white-space: wrap;
}

.state-hidden,
.state-new {
  color: var(--color-gray-500);
}

.state-hidden:hover,
.state-new:hover {
  color: var(--color-gray-800);
}

