.skip-navigation {
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.skip-navigation:hover,
.skip-navigation:active,
.skip-navigation:focus {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: var(--color-white);
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  outline: none;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 9999;
}
