body.section-faq #accordion.infinite-scroll .panel {
  border: 0;
  border-bottom: 1px solid var(--color-gray-200);
  box-shadow: none;
  margin-bottom: 1.16em;
  padding-bottom: 1.16em;
}

body.section-faq #accordion.infinite-scroll .panel:last-child {
	border-bottom: 0;
}

body.section-faq #accordion.infinite-scroll .panel-heading h4,
body.section-faq #accordion.infinite-scroll .panel-heading .heading-4 {
  font-family: var(--body-font-family);
  font-size: 0.88rem;
  font-weight: 600;
  text-transform: none;
}
