.ploneSkin table.mceLayout a.mce_unlink,
.ploneSkin table.mceLayout a.mce_underline,
.ploneSkin table.mceLayout a.mce_indent {
  margin-right: 0.48em;
}

.ploneSkin table.mceLayout a.mce_unlink span.mceIcon::after,
.ploneSkin table.mceLayout a.mce_underline span.mceIcon::after,
.ploneSkin table.mceLayout a.mce_indent span.mceIcon::after {
  border-right: var(--color-gray-400);
  content: " ";
  display: block;
  height: 2.4em;
  margin-left: 2.1em;
  margin-top: -2.2em;
}
