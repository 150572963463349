#edit-bar {
  background-color: var(--color-primary);
}

#edit-bar .label {
  font-size: inherit;
}

#edit-bar a {
  color: var(--color-white);
  text-shadow: none;
}

#edit-bar a:hover,
#edit-bar a:focus {
  color: var(--color-gray-100);
}

#edit-bar .navbar-text {
  color: var(--color-white);
}

#edit-bar li.active a,
#edit-bar li.open a,
#edit-bar .dropdown-menu {
  background-color: var(--color-primary-500);
  background-image: none;
  box-shadow: none;
  filter: none;
}

#edit-bar .dropdown-menu {
  border: 0;
  border-radius: 0;
}

#edit-bar .dropdown-menu a:hover,
#edit-bar .dropdown-menu a:focus {
  background-color: var(--color-primary);
}

#edit-bar .dropdown-menu .active.disabled a {
  background-color: var(--color-primary-600);
}

#edit-bar .dropdown-menu .dropdown-header {
  color: var(--color-primary-700);
  padding-left: 0.64em;
}

#edit-bar .caret {
  border-bottom-color: var(--color-white);
  border-top-color: var(--color-white);
}

#site-content-actions li span.label {
  margin-left: 0.32em;
}

#site-top li {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}

#site-top .navbar-nav > li > a {
  padding-bottom: 0.98em;
  padding-top: 0.98em;
}
