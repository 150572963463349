.profiles-heading {
  font-family: var(--subheading-font-family);
}

.profiles-listing .listing-results {
  /* This is a hack because of the DOM structure */
  margin-left: 0;
  margin-right: 0;
}

.listing-item-profile {
  border-bottom-color: var(--color-gray-200);
}

.listing-item-profile p {
  color: var(--color-gray-500);
  font-size: 0.8rem;
}

.listing-item-profile h2 {
  font-family: var(--body-font-family);
}

.listing-item-profile .img-thumbnail {
  border-radius: var(--base-border-radius);
}

/* Promoted Profiles */

#profiles-promoted {
  border-color: var(--feature-color-darker);
  border-radius: var(--base-border-radius);
}

#profiles-promoted .listing-item-profile {
  border-bottom: 0;
}

/* Filters */

#profiles-filters-lastname .btn-xs.btn-default {
  background-color: var(--color-primary);
}

/* Different listings */

#profiles-listing-rows .panel-heading {
  border-bottom-color: var(--feature-color-darker);
}

#profiles-listing-rows .panel-heading .panel-title {
  text-transform: var(--heading-text-transform);
}
