/*
  Grid system

  The regular bootstrap grid system. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/css/#grid). Please pay special attention to the introduction where the relationship between `.container` and `.row` and `columns` is explained. The below styles are in addition to the grid system used by bootstrap.

  Styleguide 1
*/

/*
  Rows

  Additional styles for `.row` elements. Use the classes on an `<div>` element in addition to the base `.row` class.

  .row-margin          -  A row with margins (top and bottom)
  .row-bordered        -  A row with borders (top and bottom)

  Markup:
   <div class="container">
     <div class="row {{modifier_class}}">
       <div class="col-xs-12">This is a modified row</div>
     </div>
   </div>

  Styleguide 1.1
*/

.heading-spaced {
  margin: 1.16em 0;
}

.row-margin {
  margin-bottom: 1.32em;
  margin-top: 1.32em;
}

.row-margin:first-child {
  margin-top: 0;
}

.row-bordered {
  border: 1px solid var(--color-gray-200);
}

.row-bordered:last-child {
  border-bottom: 0;
}

.page-header {
  border: 0;
  margin: 0 0 0.88em 0;
  padding-bottom: 0;
}

#above-page-body {
  margin-bottom: 1.16em;
}

#below-page-body::before {
  clear: both;
  content: " ";
  display: table;
}

/*
  Fixed height columns

  In some cases, we might want to have fixed heights for some columns. Instead of using inline styles, we will use one of these classes. Use the classes on an `<div>` element. We currently support the following heights: `50`, `70`, `90`, `100`, `120`, `200`, `600`, `1200`.

  .fixed-height-block-50           -  A fixed height block of 50px
  .fixed-height-block-100          -  A fixed height block of 100px

  Markup:
   <div class="{{modifier_class}}">This has a fixed height</div>

  Styleguide 1.2
*/

.fixed-height-block-50 {
  height: 50px;
}

.fixed-height-block-70 {
  height: 70px;
}

.fixed-height-block-90 {
  height: 90px;
}

.fixed-height-block-100 {
  height: 100px;
}

.fixed-height-block-120 {
  height: 120px;
}

.fixed-height-block-200 {
  height: 200px;
}

.fixed-height-block-600 {
  height: 600px;
}

.fixed-height-block-1200 {
  height: 1200px;
}

.item-actions {
  padding: 0 0.04em;
  text-align: right;
}

.clearfix-after::after {
  clear: both;
  content: " ";
  display: table;
}

/*
  Full width layout

  If the user chooses, he can have the haiku theme in full width.

  .layout-fullwidth-header-footer   - Class applied to the body

  Markup:
   <div class="{{modifier_class}}">
     <div class="row">
       <div class="col-xs-12">
         This row has a full width
       </div>
     </div>
   </div>

  Styleguide 1.3
*/

body.layout-fullwidth-header-footer #site-header,
body.layout-fullwidth-header-footer #site-footer,
body.layout-fullwidth-content #content {
  max-width: 100%;
}

@media (max-width: 480px){
  .item-actions {
    text-align: left;
  }}
