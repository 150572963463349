body.section-images .thumbnail > .carousel-caption > p {
  word-break: break-word;
}

.image-card {
  border: 1px solid var(--color-gray-200);
  border-radius: 4px;
}

.image-card .image-card__content {
  padding: 0.5em;
}

.image-card .image-card__content .image-container a {
  border: 1px solid var(--color-gray-200);
  border-radius: 4px;
  display: inline-block;
  height: 160px;
  overflow: hidden;
  width: 100%;
}

.image-card .image-card__content .image-container a img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.image-card .image-card__footer {
  align-items: center;
  border-top: 1px solid var(--color-gray-200);
  display: flex;
  justify-content: space-between;
  padding: 0.64em 0.5em;
}
