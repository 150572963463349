@media (min-width: 768px) {
  .haiku-slideshow.slideshow-default-tableft .carousel-indicators,
  .haiku-slideshow.slideshow-default-tabright .carousel-indicators {
    display: none;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-control-prev,
  .haiku-slideshow.slideshow-default-tabright .carousel-control-prev {
    top: -20%;
    transform: rotate(90deg);
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-control-next,
  .haiku-slideshow.slideshow-default-tabright .carousel-control-next {
    top: 395px;
    transform: rotate(90deg);
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner {
    height: 390px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item {
    backface-visibility: inherit;
    display: block;
    height: 390px;
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
    opacity: 1;
    overflow: hidden;
    padding: 0;
    perspective: none;
    position: static;
    transform: none;
    transition: none;
    width: 1140px;
    z-index: auto;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:before, .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:after,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:before,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:after {
    content: " ";
    display: table;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:after,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:after {
    clear: both;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .inner,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .inner {
    align-items: center;
    display: flex;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image {
    padding-right: 0;
    position: absolute;
    width: 75%;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image img,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image img {
    width: 100%;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption {
    border-bottom: 1px solid var(--color-gray-600);
    cursor: pointer;
    display: flex;
    height: 130px;
    overflow: hidden;
    padding: 0 2.32em;
    position: absolute;
    width: 25%;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:last-of-type .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:last-of-type .carousel-caption {
    border-bottom: 0;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption a.btn,
  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption p,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption a.btn,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption p {
    display: none;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(1) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(1) .carousel-caption {
    top: 0;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(2) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(2) .carousel-caption {
    top: 130px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(3) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(3) .carousel-caption {
    top: 260px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(4) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(4) .carousel-caption {
    top: 390px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(5) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(5) .carousel-caption {
    top: 520px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(6) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(6) .carousel-caption {
    top: 650px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(7) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(7) .carousel-caption {
    top: 780px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(8) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(8) .carousel-caption {
    top: 910px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(9) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(9) .carousel-caption {
    top: 1040px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(10) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(10) .carousel-caption {
    top: 1170px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(11) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(11) .carousel-caption {
    top: 1300px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(12) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(12) .carousel-caption {
    top: 1430px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(13) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(13) .carousel-caption {
    top: 1560px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(14) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(14) .carousel-caption {
    top: 1690px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(15) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(15) .carousel-caption {
    top: 1820px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(16) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(16) .carousel-caption {
    top: 1950px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(17) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(17) .carousel-caption {
    top: 2080px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(18) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(18) .carousel-caption {
    top: 2210px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(19) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(19) .carousel-caption {
    top: 2340px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item:nth-child(20) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item:nth-child(20) .carousel-caption {
    top: 2470px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .copyright,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .copyright {
    bottom: 10px;
    color: var(--color-gray-100);
    left: 10px;
    position: absolute;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active .carousel-caption {
    background-color: var(--color-primary-800);
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item.active .image,
  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item.active .image {
    z-index: 2;
  }

  .haiku-slideshow.slideshow-default-tableft.slideshow-item-count-2 .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright.slideshow-item-count-2 .carousel-inner .carousel-item .carousel-caption {
    height: 195px;
  }

  .haiku-slideshow.slideshow-default-tableft.slideshow-item-count-2 .carousel-inner .carousel-item:nth-child(2) .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright.slideshow-item-count-2 .carousel-inner .carousel-item:nth-child(2) .carousel-caption {
    top: 195px;
  }

  .haiku-slideshow.slideshow-default-tableft.slideshow-item-count-1 .carousel-inner .carousel-item .carousel-caption,
  .haiku-slideshow.slideshow-default-tabright.slideshow-item-count-1 .carousel-inner .carousel-item .carousel-caption {
    height: 390px;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-control-prev {
    left: 10%;
    right: auto;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-control-next {
    left: 10%;
    right: auto;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .carousel-caption {
    left: 0;
    text-align: left;
  }

  .haiku-slideshow.slideshow-default-tableft .carousel-inner .carousel-item .image {
    right: 0;
  }

  .haiku-slideshow.slideshow-default-tabright .carousel-control-prev {
    left: auto;
    right: 10%;
  }

  .haiku-slideshow.slideshow-default-tabright .carousel-control-next {
    left: auto;
    right: 10%;
  }

  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .carousel-caption {
    left: auto;
    right: 0;
  }

  .haiku-slideshow.slideshow-default-tabright .carousel-inner .carousel-item .image {
    left: 0;
  }
}
