/*
  Pagination

  Pagination. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/components/#pagination)

  Markup:
  <nav>
    <ul class="pagination">
      <li>
        <a href="#" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li><a href="#">1</a></li>
      <li><a href="#">2</a></li>
      <li><a href="#">3</a></li>
      <li><a href="#">4</a></li>
      <li><a href="#">5</a></li>
      <li>
        <a href="#" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>

  Styleguide 8.8
*/

.pagination {
  border-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
  background-color: var(--color-gray-300);
  border: 0;
  border-radius: var(--base-border-radius);
  color: var(--color-gray-900);
  margin: 0 0.64em;
  padding: 0.64em 0.64em 0.32em;
}

.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.disabled > a,
.pagination > li.disabled > span,
.pagination > li > a:hover,
.pagination > li > a:focus {
  background-color: var(--color-info-800);
  color: var(--color-info-100) !important;
}

.pagination .pagination__page a,
.pagination .pagination__first a,
.pagination .pagination__last a {
  border: 1px solid transparent;
  font-size: 1rem;
  text-decoration: none;
}

.pagination .pagination__page a:hover,
.pagination .pagination__first a:hover,
.pagination .pagination__last a:hover {
  text-decoration: none;
}

.pagination .pagination__page--current > a,
.pagination .pagination__page--current > a:active,
.pagination .pagination__page--current > a:focus,
.pagination .pagination__page--current > a:hover {
  background-color: transparent;
  border: 1px solid var(--color-gray);
  color: var(--color-gray);
  cursor: default;
}

.pagination .pagination__elipsis > span,
.pagination .pagination__elipsis > span:active,
.pagination .pagination__elipsis > span:focus,
.pagination .pagination__elipsis > span:hover {
  background-color: transparent;
}

.pagination-info {
  color: var(--color-gray-500);
  font-size: 0.88rem;
}
