.haiku-tree-list {
  display: block;
  margin: 0;
  padding: 0;
}

.haiku-tree-list li {
  list-style: none inside none;
  margin: 0;
}

.haiku-tree-list li .haiku-tree-list {
  margin-left: 1.88em;
}

.haiku-tree-node-collapse,
.haiku-tree-node-collapse + a {
  font-weight: bold;
}

.haiku-tree-node {
  align-items: center;
  border-bottom: 1px solid var(--color-gray-200);
  display: grid;
  gap: 1em;
  grid-template-areas:
    'title'
    'status'
    'meta'
    'children';
  grid-template-columns: 1fr;
  padding: 0.5em 0;
}

.haiku-tree-node__title {
  grid-area: title;
}

.haiku-tree-node__title a {
  display: inline-block;
  font-size: 1rem;
}

.haiku-tree-node__status {
  grid-area: status;
}

.haiku-tree-node__meta {
  grid-area: meta;
}

.haiku-tree-node__meta ul li {
  padding: 0;
}

.haiku-tree-node > ul.haiku-tree-list {
  grid-area: children;
}

@media (min-width: 992px){
  .haiku-tree-node {
    border-bottom-color: transparent;
    grid-template-areas:
      'title    status   meta'
      'children children children';
    grid-template-columns: 1fr 80px 0.5fr;
    padding: 0.02em 0;
  }

  .haiku-tree-node__status {
    text-align: center;
  }}
