.listing-item-file .list-item-file__title-container p.text-small,
.listing-item-file .list-item-file__details-container p.text-small {
  margin: 0;
}

.listing-item-file .listing-item-file__icon {
  font-size: 2.4rem;
}

.listing-item-file .list-item-file__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  padding-top: 0.5em;
}

@media screen and (min-width: 768px){
  .listing-item-file .list-item-file__actions {
    justify-content: space-evenly;
    margin-top: 0;
    padding-top: 0;
  }}
