#site-footer ul > li > a {
  font-size: 0.8rem;
  text-decoration: none;
}

#site-footer ul.list-inline:not(.footer-logos) {
  margin: 0;
}

#site-footer ul.list-inline:not(.footer-logos) li {
  margin: 0;
  padding: 0;
}

#site-footer ul.list-inline:not(.footer-logos)#footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#site-footer ul.list-inline:not(.footer-logos)#footer-nav li {
  padding: 0 0.5em;
}

#site-copyright {
  font-size: 0.8rem;
}

#footer-logo,
#site-copyright {
  display: flex;
  justify-content: center;
}

#homepage-footer {
  margin: 2.32em 0 1.16em;
}

#homepage-footer img {
  max-height: 5.5em;
}

ul.footer-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

ul.footer-logos > li {
  padding: 1em;
}

ul.footer-logos img {
  max-height: 5em;
}

#powered-by-haiku {
  margin-bottom: 2em;
}

#site-actions-footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#site-actions-footer ul li {
  padding: 0.5em !important;
}

@media screen and (min-width: 768px){
  #site-footer ul.list-inline:not(.footer-logos)#footer-nav {
    display: block;
  }

  #site-footer ul.list-inline:not(.footer-logos)#footer-nav li {
    display: inline-block;
    padding-right: 0.8em;
  }

  #site-footer ul.list-inline:not(.footer-logos)#footer-nav li:first-of-type {
    padding-left: 0;
  }

  #footer-logo,
  #site-copyright {
    justify-content: right;
  }

  #site-copyright {
    display: inline-block;
  }}

@media (min-width: 768px){
  ul.footer-logos {
    justify-content: space-between;
  }

  ul.footer-logos > li {
    padding: 1em;
  }}
