:root {
  /* Color theme */
  /* https://chromasome.dev/palette/haiku?Danger[]=%23ab2315&Danger[]=For%20destructive%20actions%20or%20errors&Info[]=%23195d8b&Info[]=The%20info%20color&Primary[]=%23002147&Primary[]=The%20color%20used%20most%20often%20on%20the%20website&Success[]=%23176638&Success[]=The%20success%20color&Warning[]=%23a66908&Warning[]=For%20actions%20that%20may%20cause%20problems */
  --color-primary: #002147;
  --color-primary-100: #e5f1ff;
  --color-primary-200: #b3d6ff;
  --color-primary-300: #80bbff;
  --color-primary-400: #4d9fff;
  --color-primary-500: #1a84ff;
  --color-primary-600: #006be6;
  --color-primary-700: #0053b3;
  --color-primary-800: #003b80;
  --color-primary-900: #00244d;
  --color-danger: #ab2315;
  --color-danger-100: #fceae8;
  --color-danger-200: #f7c0bb;
  --color-danger-300: #f1978d;
  --color-danger-400: #eb6d60;
  --color-danger-500: #e64333;
  --color-danger-600: #cc2a19;
  --color-danger-700: #9f2114;
  --color-danger-800: #72170e;
  --color-danger-900: #440e08;
  --color-success: #176638;
  --color-success-100: #eafaf1;
  --color-success-200: #c1f1d5;
  --color-success-300: #97e8b9;
  --color-success-400: #6dde9c;
  --color-success-500: #44d580;
  --color-success-600: #2abb67;
  --color-success-700: #219250;
  --color-success-800: #176839;
  --color-success-900: #0e3e22;
  --color-info: #195d8b;
  --color-info-100: #e9f4fb;
  --color-info-200: #bedef3;
  --color-info-300: #93c8ec;
  --color-info-400: #68b2e4;
  --color-info-500: #3c9cdc;
  --color-info-600: #2382c3;
  --color-info-700: #1b6597;
  --color-info-800: #13486c;
  --color-info-900: #0c2b41;
  --color-warning: #a66908;
  --color-warning-100: #fef5e7;
  --color-warning-200: #fbe1b6;
  --color-warning-300: #f9cc85;
  --color-warning-400: #f7b855;
  --color-warning-500: #f4a424;
  --color-warning-600: #db8a0b;
  --color-warning-700: #aa6c08;
  --color-warning-800: #7a4d06;
  --color-warning-900: #492e04;
  --color-gray: #475569;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;

  --color-white: #fff;
  --color-black: #000;

  /* Element colors */
  --link-color: var(--color-info);
  --link-color-hover: var(--color-info-700);
  --body-bg: var(--color-white);
  --body-font-color: #272727;
  --user-text-select-bgcolor: var(--color-info-300);
  --link-text-decoration: underline;
  --link-text-decoration-hover: underline;
  --subtext-font-color: var(--color-gray-600);
  --heading-color: var(--color-primary);
  --heading-border: 1px solid var(--color-gray-300);
  --feature-color: #edf4fa;
  --feature-color-darker: #bdd7ed;
  --feature-color-darkest: #89b7de;
  --feature-color-darkester: #619fd3;
  --theme-button-color: var(--color-primary-600);
  --theme-button-color-hover: var(--color-primary-800);
  --text-details: var(--color-gray-500);
  --site-header-color: var(--color-white);
  --site-header-font-color: var(--color-gray-700);
  --site-header-font-color-hover: var(--link-color);
  --site-footer-color: var(--color-gray-600);
  --site-footer-font-color: var(--link-color);
  --site-footer-font-color-hover: var(--link-color-hover);
  --label-primary: var(--color-primary-600);

  /* Plone specific */
  --plone-state-new: var(--color-primary-700);
  --plone-state-published: var(--color-success-700);
  --plone-state-hidden: var(--color-gray-700);
  --plone-state-synced: var(--color-info-700);
  --plone-state-sync_disabled: var(--color-warning-700);

  /* Fonts */
  --body-font-weight: normal;
  --body-font-style: normal;
  --base-line-height: 1.24;
  --line-height-computed: 1.16;
  --line-height-base: 1.5;

  /* Font sizes */
  --base-font-size: 16px;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.875rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 1.25rem;
  --h5-font-size: 1.125rem;
  --h6-font-size: 1rem;

  /* Headings */
  --heading-font-style: normal;
  --heading-font-weight: 300;
  --heading-line-height: 1.4;
  --heading-bottom-margin: 0.48em;
  --heading-top-margin: 0.24em;
  --heading-text-rendering: optimizeLegibility;
  --heading-text-transform: uppercase;
  --heading-font-family: helvetica, arial, sans-serif;
  --subheading-font-family: helvetica, arial, sans-serif;
  --body-font-family: helvetica, arial, sans-serif;

  /* Tables */
  --table-cell-padding: 0.48em;

  /* Forms */
  --input-border: var(--color-gray-300);
  --input-bg-disabled: var(--color-gray-400);
  --input-height-base: calc(var(--line-height-base) + (var(--padding-base-vertical) * 2) + 2);
  --input-color: var(--color-gray-800);
  --input-bg: var(--color-white);
  --input-border-radius: 4px;

  /* Spacing */
  --padding-base-vertical: 0.48em;
  --padding-base-horizontal: 0.68em;
  --padding-base: var(--padding-base-vertical) var(--padding-base-horizontal);

  /* Extra */
  --cursor-default-value: default;
  --cursor-disabled: disabled;
  --base-border-radius: 4px;

  /* Screen sizes - we don't use them yet */
  --screen-xs-max: 767px;
  --screen-sm-max: 991px;
  --screen-md-max: 1199px;
  --screen-md-min: 992px;
  --screen-xs-min: 480px;
  --screen-sm-min: 768px;
  --screen-lg-min: 1200px;
  --screen-phone: 480px;
}
