/*
  Components

  The various components that compose the layout.
  Styleguide 8
*/

.alert,
.portalMessage {
  background-image: none;
  box-shadow: none;
  filter: none;
  position: static;
}

/*
  Alerts

  Add any of the following classes to the '.alert' base class.

  .alert-success       -  A success alert
  .alert-info          -  An info alert
  .alert-warning       -  A warning alert
  .alert-danger        -  A error alert

  Markup:
  <div class="alert {{modifier_class}}">This is my alert message</div>

  Styleguide 8.3
*/

.alert li {
  margin: 0;
}

.alert-success {
  background-color: var(--color-success-100);
  border-color: var(--color-success-200);
  color: var(--color-success);
}

.alert-success p,
.alert-success li {
  color: var(--color-success);
}

.alert-success hr {
  border-top-color: var(--color-success-200);
}

.alert-success .alert-link {
  color: var(--color-success-700);
}

.alert-info {
  background-color: var(--color-info-100);
  border-color: var(--color-info-200);
  color: var(--color-info);
}

.alert-info p,
.alert-info li {
  color: var(--color-info);
}

.alert-info hr {
  border-top-color: var(--color-info-200);
}

.alert-info .alert-link {
  color: var(--color-info-700);
}

.alert-warning {
  background-color: var(--color-warning-100);
  border-color: var(--color-warning-200);
  color: var(--color-warning);
}

.alert-warning p,
.alert-warning li {
  color: var(--color-warning);
}

.alert-warning hr {
  border-top-color: var(--color-warning-200);
}

.alert-warning .alert-link {
  color: var(--color-warning-700);
}

.alert-danger {
  background-color: var(--color-danger-100);
  border-color: var(--color-danger-200);
  color: var(--color-danger);
}

.alert-danger p,
.alert-danger li {
  color: var(--color-danger);
}

.alert-danger hr {
  border-top-color: var(--color-danger-200);
}

.alert-danger .alert-link {
  color: var(--color-danger-700);
}

#site-messages .alert {
  margin: 1rem 0;
}

#site-messages .alert a:hover,
#site-messages .alert a:focus {
  text-decoration: none;
}

#site-messages .alert a::after {
  display: none;
}

#site-messages a.btn {
  color: var(--color-white);
}
