#site-header nav,
#site-parent__header nav {
  border-bottom: 1px solid var(--color-gray-400);
  margin-bottom: 0.32em;
  padding-bottom: 0.12em;
}

#site-header nav .collapse.navbar-collapse > .nav,
#site-parent__header nav .collapse.navbar-collapse > .nav {
  overflow: visible;
}

#site-header nav .collapse.navbar-collapse > .nav .open > a,
#site-header nav .collapse.navbar-collapse > .nav .open > a:hover,
#site-header nav .collapse.navbar-collapse > .nav .open > a:focus,
#site-parent__header nav .collapse.navbar-collapse > .nav .open > a,
#site-parent__header nav .collapse.navbar-collapse > .nav .open > a:hover,
#site-parent__header nav .collapse.navbar-collapse > .nav .open > a:focus {
  background-color: transparent;
}

#site-header nav .collapse.navbar-collapse > .nav li.dropdown li,
#site-parent__header nav .collapse.navbar-collapse > .nav li.dropdown li {
  padding-left: 0.9em;
  padding-right: 0.9em;
}

#site-header nav .collapse.navbar-collapse > .nav li.dropdown > .dropdown-menu > li,
#site-parent__header nav .collapse.navbar-collapse > .nav li.dropdown > .dropdown-menu > li {
  display: block;
  padding-bottom: 0.48em;
  padding-top: 0.48em;
}

#site-header nav .collapse.navbar-collapse > .nav li.dropdown > .dropdown-menu > li a,
#site-parent__header nav .collapse.navbar-collapse > .nav li.dropdown > .dropdown-menu > li a {
  text-transform: none;
}

#site-header .nav li a.dropdown-item-parent {
  border-bottom: 2px solid var(--color-gray-800);
  font-weight: bold;
  text-transform: uppercase;
}

#site-header .nav,
#site-parent__header .nav {
  display: flex;
  gap: 1.32em;
  list-style: none inside none;
  margin-left: 0;
  margin-top: 0;
  overflow: hidden;
}

#site-header .nav li,
#site-parent__header .nav li {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 0;
}

#site-header .nav li a,
#site-parent__header .nav li a {
  color: var(--color-gray-800);
  font-size: 0.88rem;
  padding: 0.32em 0;
  text-decoration: none;
  text-transform: uppercase;
}

#site-header .nav li a:hover,
#site-header .nav li a:focus,
#site-parent__header .nav li a:hover,
#site-parent__header .nav li a:focus {
  background-color: transparent;
  color: var(--link-color);
  text-decoration: none;
}

#site-header .nav li.active a,
#site-parent__header .nav li.active a {
  background-color: transparent;
  color: var(--link-color);
}

#site-header .nav li:first-child > a,
#site-parent__header .nav li:first-child > a {
  padding-left: 0;
}

#site-header .navbar-toggle,
#site-parent__header .navbar-toggle {
  float: none;
}

#site-header .navbar-header .btn,
#site-parent__header .navbar-header .btn {
  margin: 0;
}

#site-header .navbar-collapse.in,
#site-parent__header .navbar-collapse.in {
  padding-left: 0;
  padding-right: 0;
}

#site-header .navbar-collapse.in ul,
#site-parent__header .navbar-collapse.in ul {
  margin: 0;
}

#site-header .navbar-collapse.in li,
#site-parent__header .navbar-collapse.in li {
  display: block;
  margin: 0.64em 0;
  text-align: center;
  width: 100%;
}

#site-header .navbar-collapse.in li a,
#site-parent__header .navbar-collapse.in li a {
  padding-left: 0;
}

#site-header header {
  margin-bottom: 1em;
}

#site-search .input-group {
  width: 100%;
}

#site-search .input-group .btn {
  background: transparent;
  border-radius: 0;
  padding: 0;
}

#site-search .input-group .btn:active,
#site-search .input-group .btn:hover,
#site-search .input-group .btn:focus {
  box-shadow: none;
  outline: none;
}

#site-search .input-group .form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  box-shadow: none;
  font-size: 0.88rem;
  height: auto;
  line-height: 1;
  text-align: right;
}

#site-search .input-group .form-control:focus {
  font-style: normal;
  text-align: left;
}

#site-bar {
  padding-left: 0;
}

#site-actions ul {
  margin: 0 0.64em 0 0;
}

#site-actions ul li {
  margin: 0;
  padding: 0 0.5em;
}

#site-actions ul a {
  font-size: 0.8rem;
  text-decoration: none;
}

#site-actions ul a:hover {
  text-decoration: underline;
}

#icon-dropdowns {
  padding-top: 0.64em;
  text-align: center;
}

#icon-dropdowns li {
  position: relative;
  text-align: center;
}

#icon-dropdowns li > a {
  padding: 0.5em 0.9em;
}

#icon-dropdowns li > a i {
  color: var(--color-white);
  font-size: 1.08rem;
}

#icon-dropdowns li .to-reveal {
  min-width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
}

#icon-dropdowns li .to-reveal #site-search {
  padding: 0.48em 0.9em;
}

#icon-dropdowns li .social-following {
  margin: 0.9em 0 0.9em 0.9em;
  text-align: left;
}

#site-parent__header:not(:empty) {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  margin: 0 -0.9em;
}

#site-parent__header:not(:empty) .site-parent__header--centered {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1320px;
  padding: 0 0.9em;
}

#site-parent__header:not(:empty) .site-parent__header--centered nav {
  margin-bottom: 0;
  padding-bottom: 0;
}

#site-parent__header:not(:empty) nav {
  border-bottom: 0;
}

#site-parent__header:not(:empty) .navbar-nav {
  padding-right: 0;
}

#site-parent__header:not(:empty) .navbar-nav > .dropdown > .dropdown-menu {
  left: auto;
  right: 0;
}

#site-parent__header:not(:empty) .nav li:last-child a {
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  #site-parent__header nav > .navbar-collapse {
    display: flex !important;
  }

  #site-parent__header .nav {
    flex-direction: row;
  }
}

@media screen and (max-width: 991px){
  #site-header nav {
    border-bottom: 0;
  }

  #site-search .input-group {
    border-color: var(--color-gray-200);
    border-style: solid;
    border-width: 1px;
  }

  #site-search .input-group .form-control:focus,
  #site-search .input-group .form-control:active {
    border: 0;
  }}

@media (min-width: 992px){
  #icon-dropdowns {
    text-align: right;
  }

  #icon-dropdowns li {
    text-align: right;
  }}
