/*
  Forms

  Forms and form controls. Please see the full documentation on [getbootstrap.com](http://getbootstrap.com/css/#forms)

  Styleguide 4

  Form controls

  All textual `<input>` and `<textarea>` elements with `.form-control` are set to `width: 100%;` by default.

  Markup:
  <input type="text" class="form-control" placeholder="My text input" />
  <textarea class="form-control" placeholder="My text area"></textarea>

  Styleguide 4.1
*/

.form .extraActions #add-field button,
.form .extraActions #add-fieldset button,
.fieldPreview.orderable .fieldControls a {
  text-decoration: none;
  text-shadow: none;
}

.form .extraActions #add-field button:hover,
.form .extraActions #add-fieldset button:hover,
.fieldPreview.orderable .fieldControls a:hover,
.form .extraActions #add-field button:focus,
.form .extraActions #add-fieldset button:focus,
.fieldPreview.orderable .fieldControls a:focus {
  cursor: pointer;
  text-decoration: none;
}

.form-control {
  background-color: var(--input-bg);
  border-color: var(--input-border);
  border-radius: var(--base-border-radius);
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: var(--input-color);
  height: var(--input-height-base);
  padding: var(--padding-base);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: var(--input-bg-disabled);
  background-color: var(--input-bg-disabled);
}

/* .input-group .btn {
  padding: calc(var(--padding-base) * 1.5);
} */

.input-group input.btn {
  padding: calc(var(--padding-base) * 1.5) calc(var(--padding-base) * 2);
}

select.form-control,
select {
  appearance: none;
  background-color: var(--color-white);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAIAAAAmzuBxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGQTdGMTE3NDA3MjA2ODExOTJCMEI4QUY2OEFGOTJFOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NDNBNDZGRUQwRUUxMUUyQkRGNEQ3NThBREE3RDhCQiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NDNBNDZGREQwRUUxMUUyQkRGNEQ3NThBREE3RDhCQiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M0IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTE5MkIwODgxQTcwOTI5MEE3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkZBN0YxMTc0MDcyMDY4MTE5MkIwQjhBRjY4QUY5MkU5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+0G/y9QAAAKFJREFUeNp8kLEKwyAQhqsUCbqECIKDDk4ZEpwSQYiv4HPm3VxDN+21KWmbpP3g4D/+Dw4OlVIuf0FgpJRyzseuaZorsCzLPM+nRoxRa40ZY33fH2spJdQQMMw4jlVVfd1GKISw5ocBtXPu02jbVgjxNoCu6zjnayaEeO83+2VgjKdpWvMwDJTSvQEopYwxdV1ba/f/2JbbE3jDT+OUuwADANi+KdCnCjqhAAAAAElFTkSuQmCC);
  background-position: 97.5% 50%;
  background-repeat: no-repeat;
  border: 1px solid var(--input-border);
  height: var(--input-height-base);
  max-width: 100%;
  overflow: hidden;
  padding: var(--padding-base-vertical) calc(var(--padding-base-horizontal) * 2.5) var(--padding-base-vertical) var(--padding-base-horizontal);
}

select[multiple=multiple] {
  background-image: none;
  height: auto;
  overflow: scroll;
}

.ordered-selection-field tr > td > select.form-control {
  min-width: 200px;
}

.select2.select2-container {
  width: 100% !important;
}

/**
 * Easy form
 */

.fieldPreview.orderable {
  background: transparent;
  border-width: 1px;
}

.fieldPreview.orderable .form-group {
  margin-top: 30px;
}

.fieldPreview.orderable .fieldControls a {
  background-color: var(--color-info);
  border-color: var(--color-info);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  font-size: 0.88rem;
  padding: 0.32em 0.64em;
  text-shadow: none;
  transition: all 0.3s ease;
}

.form .extraActions {
  float: none !important;
  text-align: right;
}

.fieldPreview.orderable .fieldControls a:hover,
.fieldPreview.orderable .fieldControls a:focus {
  background-color: var(--link-color-hover);
  color: var(--color-white);
}

.fieldPreview.orderable .fieldControls a .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.fieldPreview.orderable .fieldControls a.schemaeditor-delete-field {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
  text-shadow: none;
  transition: all 0.3s ease;
}

.fieldPreview.orderable .fieldControls a.schemaeditor-delete-field:hover,
.fieldPreview.orderable .fieldControls a.schemaeditor-delete-field:focus {
  background-color: var(--link-color-hover);
  color: var(--color-white);
}

.fieldPreview.orderable .fieldControls a.schemaeditor-delete-field .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

.form .extraActions #add-field,
.form .extraActions #add-fieldset {
  text-decoration: none;
}

.form .extraActions #add-field:hover, .form .extraActions #add-field:focus, .form .extraActions #add-field:active,
.form .extraActions #add-fieldset:hover,
.form .extraActions #add-fieldset:focus,
.form .extraActions #add-fieldset:active {
  text-decoration: none;
}

.form .extraActions #add-field button,
.form .extraActions #add-fieldset button {
  background-color: var(--theme-button-color);
  border: none !important;
  border-color: var(--theme-button-color);
  border-radius: var(--base-border-radius);
  color: var(--color-white);
  display: inline-block !important;
  float: none !important;
  padding: 0.64em 4.24em;
  text-shadow: none;
  transition: all 0.3s ease;
}

.form .extraActions #add-field button:hover,
.form .extraActions #add-field button:focus,
.form .extraActions #add-fieldset button:hover,
.form .extraActions #add-fieldset button:focus {
  background-color: var(--link-color-hover);
  color: var(--color-white);
}

.form .extraActions #add-field button .caret,
.form .extraActions #add-fieldset button .caret {
  border-bottom-color: var(--color-white) !important;
  border-top-color: var(--color-white) !important;
}

/**
 * Select 2 styles
 */

.select2-container .select2-selection {
  border-color: var(--input-border);
  border-radius: var(--base-border-radius);
  min-height: var(--input-height-base);
  padding: var(--padding-base);
}

.select2-container .select2-selection .select2-selection__arrow {
  height: var(--input-height-base);
  right: 3px;
  width: 30px;
}

.select2-container .select2-selection.select2-selection--multiple {
  padding: 0.48em 0.48em 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 2em;
}

.select2-container.select2-container--open .select2-selection,
.select2-container.select2-container--open .select2-dropdown {
  border-color: var(--color-primary);
}

.select2-container.select2-container--open .select2-selection {
  border-bottom-color: var(--input-border) !important;
}

.select2-container li.select2-search.select2-search--inline {
  margin: 0;
  padding: 0;
}

.select2-container li.select2-selection__choice {
  margin-top: 0 !important;
}

/* Filters on haiku.software */

#search-filters select.form-control {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
