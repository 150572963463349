ol,
ul {
  list-style-position: outside;
  margin: 0.64em 0 0.64em 0.64em;
  padding-left: 1.16em;
}

ol li,
ul li {
  padding: 0;
}

ul {
  list-style-type: square;
}

ol {
  list-style-type: decimal;
}

ol ol {
  list-style-type: lower-latin;
}

ol ol ol {
  list-style-type: lower-roman;
}
