/*
  Breadcrumbs

  Indicate the current page's location within a navigational hierarchy.

  Markup:
   <ol id="site-breadcrumbs" class="breadcrumb">
     <li><span class="glyphicon glyphicon-home"></span></li>
     <li><a href="#">Link</a></li>
     <li class="active">Active</li>
   </ol>

  Styleguide 8.1
*/

#site-breadcrumbs {
  background: transparent;
  border-radius: 0;
  color: var(--color-primary);
  font-family: Arial, sans-serif;
  font-size: 0.8rem;
  line-height: 0.88em;
  margin: 0 0 0 -0.75rem; /* Neagate the margin applied from the row */
  padding: 0.64em 0;
}

#site-breadcrumbs li {
  margin: 0;
  max-width: 75ch;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#site-breadcrumbs li > a {
  opacity: 90;
  padding: 0.32em;
}

#site-breadcrumbs li > a:hover {
  opacity: 100;
}

#site-breadcrumbs li:first-child > a {
  padding-left: 0;
}

.breadcrumb > .active {
  color: var(--body-font-color);
}
