.rich-text--base blockquote span,
.rich-text--base .callout span {
  color: var(--subtext-font-color);
  display: block;
  font-size: 0.64rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.88em;
  margin-top: 1.12em;
}

.rich-text--base p.intro {
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.12em;
  margin-bottom: 1.12em;
}

.smaller.rich-text--base p, .smaller.rich-text--base li {
  color: var(--color-gray-700);
  font-size: 0.64rem;
  line-height: 0.88em;
}

.hard-left.rich-text--base ol,
.hard-left.rich-text--base ul {
  margin-left: 0;
  padding-left: 1.5em;
}

.rich-text--base h3 {
  margin-top: 1.12em;
}

.rich-text--base h3:first-child {
  border-bottom: 1px solid var(--color-gray-200);
  margin-bottom: 0.64em;
  margin-top: 0;
  padding-bottom: 0.64em;
  padding-top: 0;
}

.rich-text--base h4 {
  margin-top: 1.2em;
}

.rich-text--base ol,
.rich-text--base ul {
  margin-bottom: 1em;
  padding-left: 1.16em;
}

.rich-text--base ol li,
.rich-text--base ul li {
  padding: 0 0.64em;
}

.rich-text--base ol li ul,
.rich-text--base ol li ol,
.rich-text--base ul li ul,
.rich-text--base ul li ol {
  margin-bottom: 0;
  margin-top: -0.8em;
}

.rich-text--base ol li ul > li:first-child,
.rich-text--base ol li ol > li:first-child,
.rich-text--base ul li ul > li:first-child,
.rich-text--base ul li ol > li:first-child {
  margin-top: 0;
  padding-top: 0;
}

.rich-text--base ol li ul > li:last-child,
.rich-text--base ol li ol > li:last-child,
.rich-text--base ul li ul > li:last-child,
.rich-text--base ul li ol > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.rich-text--base ul {
  list-style-type: square;
}

.rich-text--base ol {
  list-style-type: decimal;
}

.rich-text--base ol ol {
  list-style-type: lower-latin;
}

.rich-text--base ol ol ol {
  list-style-type: lower-roman;
}

.rich-text--base a:not(.btn) {
  font-weight: bold;
}

.rich-text--base .image-left,
.rich-text--base .image-right {
  margin: 0.5em 0;
}

.rich-text--base .image-left:first-child,
.rich-text--base .image-right:first-child {
  margin-top: 0.3em;
}

.rich-text--base .image-left {
  float: left;
  margin-right: 1.5em;
}

.rich-text--base .image-right {
  float: right;
  margin-left: 1.5em;
}

.rich-text--base .image-inline {
  display: inline-block;
  margin: 0.32em;
  vertical-align: bottom;
}

.rich-text--base .image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.rich-text--base span.figure {
  position: relative;
}

.rich-text--base span.figure small + span.image-caption {
  padding-bottom: 1.24em;
}

.rich-text--base figure.image-center {
  width: 100%;
}

.rich-text--base figure.image-center .image-container {
  display: inline-block;
}

.rich-text--base figure.image-center small + figcaption {
  padding-bottom: 1.24em;
}

.rich-text--base blockquote,
.rich-text--base .callout {
  border-bottom: var(--color-gray-600);
  display: block;
  float: left;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.28em;
  margin: 1.12em 0.88em 1.12em 0;
  padding-bottom: 1.12em;
}

.rich-text--base blockquote::before,
.rich-text--base .callout::before {
  content: "“";
  display: block;
  font-size: 4.88rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -0.12em;
  text-indent: -0.12em;
}

.rich-text--base .callout {
  float: none;
  text-align: center;
}

.rich-text--base p > span.box-featured {
  display: block;
  padding: 0.64em 1.16em;
}

.rich-text--base table {
  margin-bottom: var(--line-height-computed);
  width: 100%;
}

.rich-text--base table > thead > tr,
.rich-text--base table > tbody > tr,
.rich-text--base table > tfoot > tr {
  border-bottom: 1px solid var(--color-gray-300);
}

.rich-text--base table > thead > tr:last-of-type,
.rich-text--base table > tbody > tr:last-of-type,
.rich-text--base table > tfoot > tr:last-of-type {
  border-bottom: none;
}

.rich-text--base table > thead > tr > th,
.rich-text--base table > thead > tr > td,
.rich-text--base table > tbody > tr > th,
.rich-text--base table > tbody > tr > td,
.rich-text--base table > tfoot > tr > th,
.rich-text--base table > tfoot > tr > td {
  line-height: var(--line-height-base);
  padding: var(--table-cell-padding);
  vertical-align: top;
}

.rich-text--base table > thead > tr > th {
  border-bottom: 2px solid var(--color-gray-300);
  vertical-align: bottom;
}

.rich-text--base table > caption + thead > tr:first-child > th,
.rich-text--base table > caption + thead > tr:first-child > td,
.rich-text--base table > colgroup + thead > tr:first-child > th,
.rich-text--base table > colgroup + thead > tr:first-child > td,
.rich-text--base table > thead:first-child > tr:first-child > th,
.rich-text--base table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.rich-text--base table > tbody + tbody {
  border-top: 2px solid var(--color-gray-300);
}

.rich-text--base table th {
  border-bottom: var(--color-gray-200);
  font-size: 1rem;
  font-weight: 500;
}

.rich-text--base table td,
.rich-text--base table th {
  padding: 0.25em 0.5em 0.25em 0;
}

.rich-text--base table .table {
  background-color: var(--body-bg);
}

.drop-cap.rich-text--base p:not(.intro):first-child::first-letter {
  float: left;
  font-size: 3.68rem;
  margin-right: 0.32em;
  margin-top: 0.88em;
}

.tile-content .panel-default h3,
.tile-content .panel-default .heading-3,
.tile-header h3,
.tile-header .heading-3 {
  background-color: transparent;
  font-size: calc(var(--h3-font-size) - 0.48em);
  padding-left: 0;
  text-transform: uppercase;
}

.tile-footer.text-left {
  text-align: left;
}

.tile-footer.text-right {
  text-align: right;
}

.tile-footer.text-center {
  text-align: center;
}

.tile-content.rich-text p > img,
.tile-content.rich-text p > a > img {
  display: block;
  height: auto;
  max-width: 100%;
}

.tile-content.rich-text .image-inline {
  display: inline-block;
}

.tile-content.rich-text > p:first-child {
  margin-top: 0;
}

.tile-content.rich-text > p:first-child > img:first-child {
  margin-top: 0;
}

.tile-content [class^=image-] {
  max-width: 100%;
}

.tile-content .image-left {
  float: left;
  margin-right: 1.5em;
}

.tile-content .image-right {
  float: right;
  margin-left: 1.5em;
}

.tile-content .image-inline {
  display: inline-block;
  margin: 0.32em;
  vertical-align: bottom;
}

.tile-content .image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tile-content .portlet-quickfacts {
  margin-bottom: 0.64em;
}

.row > .row-content > .column,
.row > .column {
  margin-bottom: 20px;
}

.row > .row-content > .column > div.tile,
.row > .column > div.tile {
  margin-bottom: 20px;
}

.row > .row-content {
  --bs-gutter-x: 0.8rem;
}

.row > .row-content > * {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.tile-content > .portlet:first-child {
  margin-top: 0;
}

.cover-embed-tile .text-huge {
  line-height: 1.4;
}

.cover-embed-tile .embed-description {
  background-color: var(--color-primary);
  color: var(--color-white);
  font-size: 0.88rem;
  font-weight: bold;
  margin-top: -1em;
  padding: 0.48em;
}

.cover-list-tile .carousel-caption.carousel-caption-highlight {
  background-color: var(--color-primary);
  padding: 0.5em 1em;
}

.cover-list-tile .carousel-caption.carousel-caption-highlight a {
  color: var(--color-white);
}

.cover-navigation-tile .panel-heading {
  margin-bottom: 0;
}

.cover-navigation-tile .panel-heading h3 {
  border-bottom: 1px solid var(--color-gray-200);
}

.cover-navigation-tile .panel-body a {
  text-decoration: none;
}

.cover-fluid {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.cover-fluid > .row {
  --bs-gutter-x: 0;
}

.cover-fluid > .row:not(.row-margin) > .row-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 576px) {
  .cover-fluid > .row:not(.row-margin) > .row-content {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .cover-fluid > .row:not(.row-margin) > .row-content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .cover-fluid > .row:not(.row-margin) > .row-content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .cover-fluid > .row:not(.row-margin) > .row-content {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .cover-fluid > .row:not(.row-margin) > .row-content {
    max-width: 1320px;
  }
}

.cover-fluid .row > .row-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.cover-latestpubs-tile .media-list > li > a {
  font-weight: bold;
}

body.coverpage-fluid .container {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

body.coverpage-fluid #site-header,
body.coverpage-fluid #site-footer {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.tile-header h1,
.tile-header h2,
.tile-header h3,
.tile-header h4,
.tile-header h5,
.tile-header h6,
.tile-header .heading-1,
.tile-header .heading-2,
.tile-header .heading-3,
.tile-header .heading-4,
.tile-header .heading-5,
.tile-header .heading-6 {
  color: var(--heading-color);
  text-transform: var(--heading-text-transform);
}

.cover-richtext-tile.rich-text h1 {
  border: 0;
  font-family: var(--heading-font-family);
  font-size: var(--h1-font-size);
  margin: 0 0 0.88em 0;
  padding-bottom: 0;
  padding-top: 0;
  text-transform: none;
}

.cover-col-background {
  background-color: var(--color-primary);
}

@media screen and (max-width: 992px){
  html.flexbox .row-content.flexbox-listing {
    display: block;
  }}
